import axios from '../../plugins/axios-silent';
import indexedDB from '../../plugins/indexedDB';
import { state } from './style';

export default {
    namespaced: true,

    state: {
        banner: {},
        banners: [],
        banners_list: {},
        popup_banners_list: [],
        selection_carousel_banners_list: [],
        top_banners_profile: [],
        skeletonBanners: [
            {
                id: 1,
                skeleton: true,
            },
            {
                id: 2,
                skeleton: true,
            },
            {
                id: 3,
                skeleton: true,
            },
            {
                id: 4,
                skeleton: true,
            },
            {
                id: 5,
                skeleton: true,
            },
        ]
    },
    mutations: {
        SET_BANNER(state, payload) {
            state.banner = payload;
        },
        SET_BANNERS(state, payload) {
            state.banners = payload;
        },
        SET_BANNERS_LIST(state, payload) {
            state.banners_list[payload.routeName] = payload.result;
        },
        SET_POPUP_BANNERS_LIST(state, payload) {
            state.popup_banners_list = payload;
        },
        SET_SELECTION_CAROUSEL_BANNERS(state, payload) {
            state.selection_carousel_banners_list = payload;
        },
        CLEAR_BANNERS(state, payload) {
            state.banners = [];
        },
        SET_TOP_BANNERS_PROFILE(state, payload) {
            state.top_banners_profile = payload;
        }
    },
    actions: {
        clear({ state, commit } = {}) {
            state.banners = [];
        },
        clearList({ state, commit } = {}) {
        },
        async getById({ commit }, { id }) {
            const { data } = await axios.get(`/front/banners/id/${ id }`);
        
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
            const shownCount = bannerShownCounts[data.id] || 0;
            const maxShownCount = data.max_shown_count;
        
            if (maxShownCount === null || maxShownCount === 0) {
                commit('SET_BANNER', data);
            } else {
                const shownCount = bannerShownCounts[data.id] || 0;
                if (shownCount < maxShownCount) {
                    bannerShownCounts[data.id] = shownCount + 1;
                    await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
                    commit('SET_BANNER', data);
                } else {
                    commit('SET_BANNER', null);
                }
            }
        },
        // БАНЕР СВЕРХУ
        async getByRoute({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${routeName}`);
            
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
        
            const filteredBanners = data.filter(item => {
                const maxShownCount = item.max_shown_count;
                if (maxShownCount === null || maxShownCount === 0) {
                    return true;
                }
                
                const shownCount = bannerShownCounts[item.id] || 0;
                return shownCount < maxShownCount;
            });
        
            filteredBanners.forEach(item => {
                const maxShownCount = item.max_shown_count;
                if (maxShownCount !== null && maxShownCount !== 0) {
                    bannerShownCounts[item.id] = (bannerShownCounts[item.id] || 0) + 1;
                }
            });
        
            await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
            commit('SET_BANNERS', filteredBanners);
        },        
        // КАРУСЕЛЬ БАНЕР
        async getByRouteSelectionCarousel({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/selectionCarousel/${routeName}`);
        
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
        
            const filteredBanners = data.filter(item => {
                const maxShownCount = item.max_shown_count;
                const shownCount = bannerShownCounts[item.id] || 0;
                return maxShownCount === null || maxShownCount === 0 || shownCount < maxShownCount;
            });
        
            filteredBanners.forEach(item => {
                const maxShownCount = item.max_shown_count;
                if (maxShownCount !== null && maxShownCount !== 0) {
                    bannerShownCounts[item.id] = (bannerShownCounts[item.id] || 0) + 1;
                }
            });
        
            await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
        
            commit('SET_SELECTION_CAROUSEL_BANNERS', filteredBanners);
        },
        // ТОП БАНЕР В ПРОФИЛЕ
        async getTopBannerProfile({ state, commit }) {
            const { data } = await axios.get('/front/banners/profile');
            commit('SET_TOP_BANNERS_PROFILE', data);
        },
        async getByRoutePopupList({ state, commit }) {
            const { data } = await axios.get('/front/banners/popuplist');
            const result = [];
            const placesGroups = {};
            const localData = await indexedDB.get('mircity-popupBanersIndexes') || {};
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
        
            data.sort((a, b) => (a.place > b.place) ? -1 : 1).forEach(banner => {
                if (+banner.place !== 0) {
                    if (placesGroups[+banner.place]) {
                        placesGroups[+banner.place].push(banner);
                    } else {
                        placesGroups[+banner.place] = [banner];
                    }
                }
            });
        
            const nextLocalData = {};
        
            for (const groupKey in placesGroups) {
                const group = placesGroups[groupKey];
                
                const filteredGroup = group.filter(banner => {
                    const maxShownCount = banner.max_shown_count;
                    const shownCount = bannerShownCounts[banner.id] || 0;
        
                    return maxShownCount === null || maxShownCount === 0 || shownCount < maxShownCount;
                });
        
                if (filteredGroup.length === 0) continue;
        
                const lastShownId = localData[groupKey] || null;
                const lastShowBannerIndex = lastShownId ? filteredGroup.findIndex(b => b.id === lastShownId) : -1;
                const currentBanner = filteredGroup[+lastShowBannerIndex + 1] || filteredGroup[0];
        
                const maxShownCount = currentBanner.max_shown_count;
                if (maxShownCount !== null && maxShownCount !== 0) {
                    bannerShownCounts[currentBanner.id] = (bannerShownCounts[currentBanner.id] || 0) + 1;
                }
        
                result.push(currentBanner);
                nextLocalData[groupKey] = currentBanner.id;
            }
        
            await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
            await indexedDB.set('mircity-popupBanersIndexes', nextLocalData);
        
            commit('SET_POPUP_BANNERS_LIST', result);
        },           
        async getByRouteListPlaces({ state, commit }, { routeName }) {
            const { data } = await axios.get(`/front/banners/list/${routeName}`);
        
            const result = [];
            const placesGroups = {};
            const localData = await indexedDB.get('mircity-banersIndexes') || {};
        
            const bannerShownCounts = await indexedDB.get('mircity-bannerShownCounts') || {};
            for (const banner of data.sort((a, b) => (a.place > b.place) ? -1 : 1)) {
                if (+banner.place !== 0) {
                    if (placesGroups[+banner.place]) {
                        placesGroups[+banner.place].push(banner);
                    } else {
                        placesGroups[+banner.place] = [banner];
                    }
                }
            }
        
            const nextLocalData = {};
        
            for (const groupKey in placesGroups) {
                const group = placesGroups[groupKey];
        
                const filteredGroup = group.filter(banner => {
                    const maxShownCount = banner.max_shown_count;
                    const shownCount = bannerShownCounts[banner.id] || 0;
                    return maxShownCount === null || maxShownCount === 0 || shownCount < maxShownCount;
                });
        
                if (filteredGroup.length === 0) continue;
        
                const lastShownId = localData[groupKey] || null;
                const lastShowBannerIndex = lastShownId ? filteredGroup.findIndex(b => b.id === lastShownId) : -1;
                const currentBanner = filteredGroup[+lastShowBannerIndex + 1] || filteredGroup[0];
        
                const maxShownCount = currentBanner.max_shown_count;
                if (maxShownCount !== null && maxShownCount !== 0) {
                    bannerShownCounts[currentBanner.id] = (bannerShownCounts[currentBanner.id] || 0) + 1;
                }
        
                result.push(currentBanner);
                nextLocalData[groupKey] = currentBanner.id;
            }
        
            await indexedDB.set('mircity-bannerShownCounts', bannerShownCounts);
            await indexedDB.set('mircity-banersIndexes', nextLocalData);
        
            commit('SET_BANNERS_LIST', { routeName, result });
        },           
    }
}

