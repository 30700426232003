<template>
    <f7-sheet
        class="review-dialog-sheet"
        bottom
        :close-by-backdrop-click="false"
        :close-by-outside-click="false"
        :swipe-to-close="false"
        backdrop
        @sheet:closed="closeOrder"
    >
        <div class="review-dialog-sheet__inner">
            <div class="review-dialog-sheet__title">
                <h2>Заказ выполнен</h2>
                <h3>Оцените работу нашей команды</h3>
            </div>
            <div class="review-dialog-sheet__rate">
                <button
                    v-for="index in 5"
                    :key="index"
                    @click.stop="updateRating(index)"
                >
                    <svg
                        width="41"
                        height="41"
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M40.8931 15.9429C40.6247 15.1007 39.8884 14.5025 39.0172 14.4228L27.1835 13.3327L22.5041 2.22132C22.1591 1.407 21.3733 0.879883 20.5002 0.879883C19.6271 0.879883 18.8414 1.407 18.4963 2.22323L13.817 13.3327L1.98135 14.4228C1.11173 14.5044 0.377248 15.1007 0.10729 15.9429C-0.162668 16.7852 0.0866441 17.709 0.74449 18.2913L9.68938 26.2498L7.05174 38.0372C6.85874 38.9039 7.19032 39.7997 7.89915 40.3196C8.28016 40.5988 8.72592 40.741 9.17543 40.741C9.56301 40.741 9.94746 40.635 10.2925 40.4255L20.5002 34.2363L30.7042 40.4255C31.4509 40.8813 32.3921 40.8397 33.0994 40.3196C33.8085 39.7981 34.1398 38.902 33.9468 38.0372L31.3092 26.2498L40.2541 18.2929C40.9119 17.709 41.1631 16.7868 40.8931 15.9429Z"
                            :fill="rating >= index ? '#FFC107' : '#E4E4E4'" />
                    </svg>
                </button>
            </div>
            <div class="review-dialog-sheet__details">
                <button
                    :style="{ background }"
                    :class="`custom-button gradient-${background}`"
                    @click.stop="leaveTips()"
                >
                    Оставить чаевые
                </button>
            </div>
            <!-- <div class="review-dialog-sheet__details">
                <f7-link
                    class="flex flex-col items-center"
                    :href="`/orders/${order.id}`"
                    :route-props="{ showComleted: true }"
                >
                    <f7-icon f7="info_circle" />
                    Детали заказа
                    <f7-icon f7="chevron_right" />
                </f7-link>
            </div> -->
            <div class="review-dialog-sheet__buttons">
                <f7-link
                    href="/feedback"
                    class="custom-button"
                >
                    Связаться с нами
                </f7-link>
                <button
                    :style="{ background }"
                    :class="`custom-button gradient-${background}`"
                    @click.stop="doneReview"
                >
                    Готово
                </button>
            </div>
        </div>
    </f7-sheet>
</template>

<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';

    import { ORDER_STATUS, ORDER_TYPE, BUSINESS_TYPE_MAP } from '../../js/utils/vars';
    import { goToExternalLink } from '../../js/utils/service-helpers';

    export default {
        name: 'ReviewDialog',

        props: {
            canOpen: {
                type: Boolean,
                default: false,
            }
        },
        
        data: () => ({
            BUSINESS_TYPE_MAP,
            order: {},
            rating: 0,
            tips: 0,
            waiting: false,
            background: '',
            inputError: false,
            tipsId: process.env.VUE_APP_CHACHACHAY_TIPS_DEFAULT_ID
        }),

        computed: {
            ...mapState('orders', ['entities']),
            ...mapState('style', ['colors', 'backgrounds']),
        },

        watch: {
            entities: {
                deep: true,
                async handler() {
                    // console.log('ReviewDialog entities watch');
                    const finished = [ORDER_STATUS.CANCELED];
                    for (let i = 0; i < this.entities.length; i++) {
                        const order = this.entities[i];
                        if (finished.includes(order.status)) {
                            this.order = order;
                            await this.deleteOrder();
                        }
                        if ([ORDER_STATUS.PAYMENT_CORRECTION, ORDER_STATUS.DONE].includes(order.status)) {
                            this.order = order;
                            this.waiting = true;
                            await this.openDialog();
                        }
                    }
                }
            },
            canOpen() {
                this.openDialog();
            }
        },

        async mounted() {
            await this.$store.dispatch('style/fetch');
        },

        methods: {
            async openDialog() {
                this.$emit('waiting');

                if (this.waiting && this.order?.id) {
                    if (this.order.type === ORDER_TYPE.ENTITY_DELIVERY) {
                        const code = this?.order?.ed?.items[0]?.entity?.entityType?.code;
                        if (code && this.backgrounds) {
                            this.background = this?.backgrounds[code] ? `${this?.backgrounds[code]} !important` : '';
                        }
                    } else {
                        this.background = BUSINESS_TYPE_MAP[this.order?.type];
                    }
                    if (this.order.concierge && this.order.concierge.tips_layout_id) {
                        this.tipsId = this.order.concierge.tips_layout_id;
                    }
                    this.$f7.sheet.open('.review-dialog-sheet');
                    this.waiting = false;
                    this.$emit('processing', true);
                    return;
                }
            },

            async doneReview() {
                if (this.tips > 0) {
                    await this.pay();
                    return;
                }
                this.$f7.sheet.close();
            },

            async closeOrder() {
                if(this.order && this.order.id) {
                    const review = {
                        order_id: this.order.id,
                        rating: this.rating,
                        tips: +this.tips || null,
                    }
                    await store.dispatch('orders/sendReview', { review });
                    await this.deleteOrder();
                }
                this.$emit('processing', false);
                this.background = '';
                this.rating = 0;
                this.tips = 0;
                window.dispatchEvent(new Event('resize'));
            },

            async deleteOrder() {
                const ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                await indexedDB.set('mircity-ordersInWork', ordersInWork?.filter(order => order?.id !== this.order.id));
                await indexedDB.set('mircity-orderForRepeat', this.order);
                this.order = {};
                this.rating = 0;
            },

            updateRating(rating) {
                this.rating = rating === this.rating ? 0 : rating;
            },

            leaveTips() {
                goToExternalLink(`${ process.env.VUE_APP_CHACHACHAY_TIPS_URL }/${ this.tipsId }`);
                // if (window.cordova && window.cordova.InAppBrowser) {
                //     const inAppBrowserRef =
                //         window.cordova.InAppBrowser.open(
                //             `${ process.env.VUE_APP_CHACHACHAY_TIPS_URL }/${ this.tipsId }`,
                //             '_blank',
                //             [
                //                 'zoom=no',
                //                 'location=yes',
                //                 'hidenavigationbuttons=yes',
                //                 'hideurlbar=yes',
                //                 'toolbar=yes',
                //                 'toolbarposition=top',
                //                 'toolbarcolor=#FFFFFF',
                //                 'footer=no',
                //                 'closebuttoncaption=Отмена',
                //                 'closebuttoncolor=#E2231A',
                //                 // "lefttoright=yes",
                //                 'clearsessioncache=yes',
                //                 'clearcache=yes',
                //                 'presentationstyle=fullscreen',
                //                 'fullscreen=no',
                //             ].join(',')
                //         );

                //     inAppBrowserRef.addEventListener(
                //         'loadstop',
                //         function () {
                //             inAppBrowserRef.insertCSS({
                //                 code: '`body{ padding-top: constant(safe-area-inset-top); padding-top: env(safe-area-inset-top); padding-bottom: constant(safe-area-inset-bottom); padding-bottom: env(safe-area-inset-bottom); }`',
                //             });
                //         }
                //     );

                //     // inAppBrowserRef.addEventListener(
                //     //     'loadstart',
                //     //     async event => {
                //     //         let tmpUrl = event.url.split('?');
                //     //         if (tmpUrl[0] == process.env.VUE_APP_CLOUD_PAYMENTS_SUCCESS_URL) {
                //     //             inAppBrowserRef.close();
                //     //             sendAppMetricaEvent('PAYMENT_SUCCESS', {
                //     //                 Service: this?.items[0]?.entity_type_code,
                //     //                 Partner: this?.items[0]?.entity_code
                //     //             });
                //     //             this.$f7router.navigate({
                //     //                 name: 'payment-status',
                //     //                 params: {
                //     //                     status: 'success',
                //     //                 },
                //     //                 query: {
                //     //                     orderId: payData.orderId
                //     //                 }
                //     //             });
                //     //         }
                //     //         if (tmpUrl[0] == process.env.VUE_APP_CLOUD_PAYMENTS_FAIL_URL) {
                //     //             inAppBrowserRef.close();
                //     //             sendAppMetricaEvent('PAYMENT_FAILED', {
                //     //                 Service: this?.items[0]?.entity_type_code,
                //     //                 Partner: this?.items[0]?.entity_code
                //     //             });
                //     //             this.$f7router.navigate({
                //     //                 name: 'payment-status',
                //     //                 params: {
                //     //                     status: 'fail',
                //     //                 },
                //     //                 query: {
                //     //                     orderId: payData.orderId,
                //     //                 }
                //     //             });
                //     //         }
                //     //     }
                //     // );
                // } else {
                //     window.open(`${ process.env.VUE_APP_CHACHACHAY_TIPS_URL }/${ this.tipsId }`, '_blank');
                // }
                this.$f7.sheet.close();
            },
        }
    };
</script>

<style lang="scss" scoped>
.review-dialog-sheet {
    height: auto;
    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__title {
        flex: 0 0 auto;
        h2 {
            margin-bottom: 10px;

            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
        }
        h3 {
            margin-bottom: 25px;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: #707378;
        }

        &-pay {
            h2 {
                text-align: left;
                font-size: 20px;
                line-height: 23px;
            }
        }
    }
    &__rate {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 277px;
        margin: 0 auto 30px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__tips-users {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;

        padding-bottom: 15px;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        button {
            min-width: 92px;
            height: 50px;
            margin-top: 7px;

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(35, 35, 33, 0.12);
            border-radius: 36px;

            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }
            &:last-child {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    &__tips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;

        padding-bottom: 15px;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        button {
            width: 92px;
            min-width: 92px;
            height: 50px;
            margin-right: 5px;

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(35, 35, 33, 0.12);
            border-radius: 36px;

            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }
            &:last-child {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    &__details {
        width: 100%;
        margin-bottom: 32px;
        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            i {
                font-size: 16px;
                &:first-child {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }
    &__buttons {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        button, a {
            width: 100%;
            height: 43px;

            outline: 2px solid transparent;
            outline-offset: 2px;

            font-size: 13px;
            line-height: 15px;
    
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    &__input {
        margin: 10px 0;

        input {
            width: 100%;
            padding: 14px 20px 15px;

            border: 1px solid #EEEEEE;
            border-radius: 36px;

            font-size: 13px;
            line-height: 15px;
            color: #707378;
        }

        &-error {
            input {
                border: 1px solid #ff3b30;
            }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
     
        input[type="number"]:hover,
        input[type="number"]:focus {
            -moz-appearance: number-input;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
</style>
