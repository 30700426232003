import axios from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        list: [],
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        }
    },
    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/partners');
            commit('SET_LIST', data);
        },
    },
}
