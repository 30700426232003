import axios from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        towers: [],
        discountCards: []
    },
    mutations: {
        SET_TOWERS(state, payload) {
            state.towers = payload;
        },
        SET_DISCOUNT_CARDS(state, payload) {
            state.discountCards = payload;
        },
    },
    actions: {
        async getTowers({ commit }) {
            const { data } = await axios.get('/front/info/towers');
            commit('SET_TOWERS', data);
        },
        async getDiscountCards({ commit }) {
            const { data } = await axios.get('/front/discount-cards');
            commit('SET_DISCOUNT_CARDS', data);
        },
    },
}
