<template>
    <div v-if="text.length < 250" v-html="text"></div>
    <div v-else class="show-hide-text" :class="{ 'showed': showMore }">
        <div v-html="text"></div>
        <a class="show-less" @click="showMore = false">Скрыть </a>
        <a class="show-more" @click="showMore = true">Подробнее </a>
    </div>
</template>
<script>
    export default {
        name: 'ShowHideText',
        props: {
            text: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                showMore: false
            }
        }
    };
</script>
<style lang="scss">
.show-hide-text {
    display: flex;
    flex-wrap: wrap;

    div {
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    a {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        padding-right: 20px;
        color: #32323280;
    }
    a:after {
        content:"";
        display: inline-block;
        width: 16px;
        height: 8px;
        background: url('../../static/vector.svg') right center no-repeat;
    }
    .show-less {
        display: none;
    }
    .show-less:after {
        transform: scaleY(-1);
    }

    &.showed {
        div {
            display: block;
            max-height: 100%;
        }
        .show-less {
            display: block;
        }
        .show-more {
            display: none;
        }
    }
}
</style>