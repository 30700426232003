import moment from '../../plugins/moment';
import { f7 } from 'framework7-vue';
import { Device } from 'framework7';
import store from '@/store';
import axios from '../../plugins/axios';
import indexedDB from '../../plugins/indexedDB';
import { ORDER_TYPE, PUSH_TYPE } from '@/js/utils/vars';
import { BUSINESS_TYPE, DELIVERY_TYPE } from './vars';
const { version } = require('../../../package.json');

export const checkEnabledService = order => {
    if (order.type === ORDER_TYPE.RESTAURANT_DELIVERY) {
        const service = order?.rd?.items?.[0]?.restaurant;
        return service && !!service.enabled;
    }
    if (order.type === ORDER_TYPE.GROCER_DELIVERY) {
        const service = order?.gd?.items?.[0]?.grocer;
        return service && !!service.enabled;
    }
    if (order.type === ORDER_TYPE.ENTITY_DELIVERY) {
        const service = order?.ed?.items?.[0]?.entity;
        return service && !!service.enabled;
    }
    return false;
};

export const goToExternalLink = (link, data) => {
    if(!data) {
        data = { blank: true }
    }
    if(window.cordova && window.cordova.InAppBrowser) {
        window.cordova.InAppBrowser.open(link, '_system', ['location=yes', 'hidden=yes'].join(','));
    } else {
        window.open(link, data.blank ? '_blank' : '_self');
    }
    return false;
}

const getDeviceId = async () => {
    if(window?.plugins?.uniqueDeviceID) {
        return await new Promise(resolve => {
            window.plugins.uniqueDeviceID.get(deviceId => resolve(deviceId));
        });
    }
    // return 'test-device-id';
    return null;
}
export { getDeviceId };

export const getUserId = () => {
    return store?.state?.auth?.user?.id || null;
}

export const priceFormat = price => {
    price = Math.round(+price * 100) / 100;
    const priceArr = price.toString().split('.');
    priceArr[0] = priceArr[0].replace(/[^\d]/g, '');
    if(priceArr[0].length > 3) {
        priceArr[0] = priceArr[0].slice(0, -3) + '\u202F' + priceArr[0].slice(-3);
    }
    if(priceArr[1] === '00') {
        priceArr.splice(1, 1);
    }
    if(priceArr[1] && priceArr[1].length === 1) {
        priceArr[1] = priceArr[1] + '0';
    }
    return priceArr.join('.');
}

export const weightFormat = weight => {
    if(weight && weight > 0) {
        if(weight >= 1000) {
            return `${weight / 1000} кг`
        }
        return `${weight} гр`
    }
    return '0 гр'
}

export const getEntitiesClosedList = services => {
    const currentWeekDay = moment().isoWeekday() - 1
    const openServices = []
    const closedServices = []
    const filtered = services.map(service => {
        let isClosed = true
        if(!service.schedule) {
            return {
                ...service,
                closed: true
            }
        }
        const foundScheduleItem = service.schedule.find(item => item.day_of_week === currentWeekDay)
        if (foundScheduleItem) {
            // Stop restaurant
            if (foundScheduleItem?.stop_until) {
                const isNow = moment().isSameOrBefore(foundScheduleItem?.stop_until);
                // Mutations for stopping for a while
                // It will be clear after friday refactor :)
                if (isNow) {
                    foundScheduleItem.open_at = moment(foundScheduleItem?.stop_until).format('HH:mm');
                    foundScheduleItem.close_at = '23:59';
                    foundScheduleItem.around_the_clock = false;
                    service.extended_schedule = false;
                    service.hide = false;
                }
            }

            if (!service.enabled) {
                return {
                    ...service,
                    closed: true
                }
            }
            if (service.not_close_service) {
                return {
                    ...service,
                    closed: false
                }
            }
            if (foundScheduleItem.around_the_clock) {
                return {
                    ...service,
                    closed: false
                }
            }

            let startTime = moment(foundScheduleItem.open_at, 'HH:mm')
            let endTime = moment(foundScheduleItem.close_at, 'HH:mm')

            const afterStart = moment().isSameOrAfter(startTime)
            const isToday = afterStart && moment().isSameOrAfter(moment('00:00', 'HH:mm'))
            const beforeClose = moment().isSameOrBefore(endTime)

            if (service.extended_schedule) {
                // If start after end
                const extended = startTime.isSameOrAfter(endTime);
                if (extended) {
                    const checkTodayBeforeMidnight = isToday && afterStart;
                    // If more than 00:00 but less than close time we dont add the day
                    if (!beforeClose && checkTodayBeforeMidnight) {
                        endTime = moment('23:59', 'HH:mm')
                    } else {
                        startTime = moment('00:00', 'HH:mm')
                    }
                }
            }

            const check = foundScheduleItem.enabled &&
                moment().isSameOrAfter(startTime) &&
                moment().isSameOrBefore(endTime);

            if (check) {
                isClosed = false
            }
        }

        return {
            ...service,
            closed: isClosed
        }
    })

    filtered.forEach(item => {
        if (item.closed) {
            closedServices.push(item)
        } else {
            openServices.push(item)
        }
    })

    return openServices.concat(closedServices)
}

export const getMinMaxTimeString = (min = 0, hour = 0, maxMin = 0, maxHour = 0, checkTwoRows = false) => {
    let time = '';
    const minStr = `${min} мин`;
    const hourStr = `${hour} ч`;
    const maxMinStr = `${maxMin} мин`;
    const maxHourStr = `${maxHour} ч`;
    if (checkTwoRows && maxHour && maxMin) {
        time = `${maxHour} ч ${maxMin} мин`
        return time;
    } else {
        if (hour) {
            time += hourStr;
        }
        if (min) {
            time += ` ${minStr}`;
        }
        if (min && !hour && maxMin && !maxHour) {
            time = `${min} - ${maxMinStr}`;
        }
        if (!min && hour && !maxMin && maxHour) {
            time = `${hour} - ${maxHourStr}`;
        }
        if (min && !hour && maxMin && maxHour) {
            time = `${minStr} - ${maxHourStr} ${maxMinStr}`;
        }
        if (min && !hour && !maxMin && maxHour) {
            time = `${minStr} - ${maxHourStr}`;
        }
        if (min && hour && maxMin && maxHour) {
            time = `${hourStr} ${min ? minStr : ''} - ${maxHourStr} ${maxHour ? maxMinStr : ''}`;
        }
        if (!min && hour && maxMin && maxHour) {
            time = `${hourStr} ${min ? minStr : ''} - ${maxHourStr} ${maxHour ? maxMinStr : ''}`;
        }
    }
    return time;
}

const createModal = ({
    modalText = '',
    okText = '',
    rejectText = '',
    open = false,
}) => new Promise((resolve, reject) => {
    if (open) {
        f7.dialog.create({
            text: modalText,
            buttons: [{
                text: okText,
                cssClass: 'dialog-button-black',
                onClick: () => {
                    store.commit('cart/CLEAR');
                    resolve();
                },
            },
            {
                text: rejectText,
                onClick: () => {
                    reject();
                },
            }]
        }).open();
    } else {
        resolve();
    }
});

export { createModal };

export const addItemToCart = ({ orderType, paymentType, cartItem, profile = false }, callback) => {
    const cartOrderType = store.state.cart.orderType;
    const cartItems = store.state.cart.items;
    const partnersList = store.state.partners.list;     

    const partnersMapping = {
        [ORDER_TYPE.RESTAURANT_DELIVERY]: {
            group: 'restaurants',
            id: 'restaurant_id',
            type: BUSINESS_TYPE.RESTAURANT
        },
        [ORDER_TYPE.GROCER_DELIVERY]: {
            group: 'grocers',
            id: 'grocer_id',
            type: BUSINESS_TYPE.GROCERY
        },
        [ORDER_TYPE.ENTITY_DELIVERY]: {
            group: 'entities',
            id: 'entity_id',
            type: cartItem.entity_type_code
        }
    }

    cartItem.removed = false;
    
    cartItem.item_type = partnersMapping[orderType].type;

    cartItem.old_price = cartItem.old_price ? cartItem.old_price : cartItem.price;
    cartItem.old_item_price = cartItem.old_item_price ? cartItem.old_item_price : cartItem.item_price;

    if (cartItems.length > 0) {
        for (var i = 0; i < partnersList.length; i++) {
            if (partnersList[i][partnersMapping[orderType].group].filter(item => item == cartItems[0][partnersMapping[orderType].id]).length > 0) {
                if (partnersList[i][partnersMapping[orderType].group].filter(item => item == cartItem[partnersMapping[orderType].id]).length == 0) {
                    const prom = createModal({
                        modalText: `В корзине есть товары из других партнеров. Если вы продолжите, то они будут удалены из корзины. Добавить “${cartItem.title}“ в корзину?`,
                        okText: 'Давайте',
                        rejectText: 'Нет',
                        open: true
                    });
                
                    prom.then(async () => {
                        store.dispatch('cart/setOrderType', orderType);
                        store.dispatch('cart/setPaymentType', paymentType);
                        store.dispatch('cart/addItem', {
                            item: cartItem
                        });
                        await store.dispatch('cart/setCacheData');
                
                        if (cartItems.length === 1) {
                            sendAppMetricaEvent('ORDER_CART', {
                                Service: cartItem.entity_type_code,
                                Partner: cartItem.entity_code
                            });
                        }
                        let event = profile ? 'profile_popular_add_to_cart' : 'add_to_cart';

                        sendAppMetricaEvent(event, {
                            Service: cartItem.item_type,
                            Partner: cartItem.entity_code,
                            ProductId: cartItem.id,
                            Position: cartItem.position ? cartItem.position : -1,
                            Product: cartItem.title,
                            Price: cartItem.price,
                            Quantity: cartItem.quantity
                        })
                        // sendAppsFlyerEvent('af_add_to_cart', {
                        //     af_price: cartItem.price,
                        //     af_content: cartItem.title,
                        //     af_content_id: cartItem.id,
                        //     af_content_type: cartItem.item_type,
                        //     af_currency: 'RUB',
                        //     af_quantity: cartItem.quantity
                        // });
                    }).then(() => {
                        callback();
                    });
                    return;
                }
            }
        }
    }

    const prom = createModal({
        modalText: `В корзине есть товары из других разделов. Если вы продолжите, то они будут удалены из корзины. Добавить “${cartItem.title}“ в корзину?`,
        okText: 'Давайте',
        rejectText: 'Нет',
        open: cartOrderType !== orderType && cartItems.length > 0
    });

    prom.then(async () => {
        store.dispatch('cart/setOrderType', orderType);
        store.dispatch('cart/setPaymentType', paymentType);
        store.dispatch('cart/addItem', {
            item: cartItem
        });
        await store.dispatch('cart/setCacheData');
                
        if (cartItems.length === 1) {
            sendAppMetricaEvent('ORDER_CART', {
                Service: cartItem.entity_type_code,
                Partner: cartItem.entity_code
            });
        }
        sendAppMetricaEvent('add_to_cart', {
            Service: cartItem.item_type,
            Partner: cartItem.entity_code,
            ProductId: cartItem.id,
            Position: cartItem.position ? cartItem.position : -1,
            Product: cartItem.title,
            Price: cartItem.price,
            Quantity: cartItem.quantity
        })
        // sendAppsFlyerEvent('af_add_to_cart', {
        //     af_price: cartItem.price,
        //     af_content: cartItem.title,
        //     af_content_id: cartItem.id,
        //     af_content_type: cartItem.item_type,
        //     af_currency: 'RUB',
        //     af_quantity: cartItem.quantity
        // });
    }).then(() => {
        callback();
    });
}

export const checkVersion = async ({ key, this_ }) => {
    const lastVersionCheck = await indexedDB.get('mircity-lastVersionCheck') || null;

    if((new Date().getTime() - new Date(lastVersionCheck).getTime()) > (process.env.VUE_APP_VERSION_CHECK_DELAY * 1000)) {
        const { data: serverVersion } = await axios.get(`/front/version/${ key }`);
        await indexedDB.set('mircity-lastVersionCheck', new Date().toISOString());
        if(versionVal(version) < versionVal(serverVersion)) {
            if(Device.cordova) {
                const { data: forceData } = await axios.get('/front/version/force');
                if(versionVal(version) < versionVal(forceData.minVersion)) {
                    this_.$root.$emit('ForceUpdatePopupShow', forceData);
                } else {
                    f7.dialog.create({
                        title: 'Доступна новая версия приложения',
                        text: 'Мы подготовили новую версию приложения. Пожалуйста, обновите ее, чтобы пользоваться всеми возможностями.',
                        buttons: [{
                            text: 'Обновить',
                            cssClass: 'dialog-button-black',
                            onClick: () => {
                                if(Device.ios) {
                                    window.cordova.InAppBrowser.open(process.env.VUE_APP_APP_LINK_IOS, '_system', ['location=yes', 'hidden=yes'].join(','));
                                } else {
                                    window.cordova.InAppBrowser.open(process.env.VUE_APP_APP_LINK_ANDROID, '_system', ['location=yes', 'hidden=yes'].join(','));
                                }
                            },
                        },
                        {
                            text: 'Отложить'
                        }]
                    }).open();
                }
            } else {
                if('caches' in window){
                    caches.keys().then(names => {
                        names.forEach(async name => {
                            await caches.delete(name);
                        });
                    });
                }
                window.location.reload();
            }
        }   
    }
}

const versionVal = version => {
    return ((version.split('.')[0] * 1000000) + (version.split('.')[1] * 1000) + (version.split('.')[2] * 1))
}

export { versionVal };

export const updatePushToken = async (forced = false) => {
    const device_id = await getDeviceId();

    if(Device.cordova && window.FirebasePlugin && device_id) {
        const tokenOld = await indexedDB.get('mircity-PushToken');
        window.FirebasePlugin.getToken(async token => {
            if(token) {
                if(forced || (tokenOld !== token)) {
                    await indexedDB.set('mircity-PushToken', token);
                    axios.post('/front/customer/push-token', { os: Device.os, token, device_id });
                }
            }
        }, (error) => {});
    }
}

export const pushAction = ({ type, data }) => {
    switch (type) {
        case PUSH_TYPE.LINK:
            if(data && data.link) {
                f7.views[0].router.navigate(data.link);
            }
            break;
        case PUSH_TYPE.ORDER_STATUS:
            if(data && data.order_id) {
                f7.views[0].router.navigate({
                    name: 'orders-detail',
                    params: {
                        orderId: data.order_id
                    }
                });
            }
            break;
    }
}

export const sendCustomerLog = async type => {
    if(Device.cordova) {
        const customerLog = await indexedDB.get('mircity-customerLog') || [];
        if(!customerLog[type]) {
            const device_id = await getDeviceId();
            await axios.post('/front/customer-log', { 
                device_id, 
                type, 
                app_version: version,
                browser: !Device.cordova,
                os: Device.os,
                os_version: Device.osVersion,
                data: {},
                app_version_val: versionVal(version),
            });
            customerLog[type] = new Date().getTime();
            await indexedDB.set('mircity-customerLog', customerLog);

            await new Promise(resolve => setTimeout(resolve, 500));

            sendAppMetricaEvent({
                1: 'FIRST_OPEN',
                2: 'FIRST_LOGIN'
            }[type] + '_' + Device.os.toUpperCase());
        }
    }
}

export const calculateDeliveryPrice = ({ delivery = {}, orderPrice = null, deliveryType = null }) => {
    const { price = [], address = [] } = delivery;
    let sum = 0;
    let byPrice = 0;
    let byAddress = 0;

    if (address?.length) {
        const type = address?.find(item => deliveryType ? item.delivery_type === deliveryType : item.delivery_type === DELIVERY_TYPE.DOOR);

        if(type.free) {
            return {
                sum: 0,
                price: 0,
                address: 0
            };
        }

        sum += type?.price || 0;
        byAddress += type?.price || 0;
    }

    if (price?.length) {
        const filteredOptions = price
            .filter(({ from = 0 }) => from <= orderPrice)
            .sort((a, b) => a.from - b.from);

        const closerOption = filteredOptions[filteredOptions.length - 1];
        if (closerOption) {
            sum += closerOption.price;
            byPrice += closerOption.price;
        }
    }

    return {
        sum: sum ? sum : null,
        price: byPrice,
        address: byAddress
    };
};

export const getCloseDeliveryDiscount = ({ delivery = {}, orderPrice = null }) => {
    let result = [0, 0];
    if (!delivery?.id || (!orderPrice && orderPrice !== 0)) {
        return result;
    }

    const { price = [], address = [] } = delivery;

    if (address?.length) {
        const type = address?.find(item => item.delivery_type === DELIVERY_TYPE.DOOR);
        if(type?.free) {
            return result;
        }
        result[0] += type?.price || 0;
    }

    if (price?.length) {
        const filteredOptions = price
            .filter(({ from = 0 }) => from > orderPrice)
            .sort((a, b) => a.from - b.from);

        const closerOption = filteredOptions[0];
        if (closerOption) {
            result[0] = closerOption.price;
            result[1] = closerOption.from - orderPrice;
        }
    }

    return result;
};
    
export const getDeliveryPriceRange = delivery => {
    let min = 0;
    let max = 0;

    const { price = [], address = [] } = delivery;

    if (price?.length) {
        const findMin = Math.min(...price.map(({ price = 0 }) => price));
        const findMax = Math.max(...price.map(({ price = 0 }) => price));
        min += findMin;
        max += findMax;
    }

    if (address?.length) {
        const findMin = Math.min(...address.map(item => item.free ? 0 : (item.price || 0)));
        const findMax = Math.max(...address.map(item => item.free ? 0 : (item.price || 0)));
        min += findMin;
        max += findMax;
    }

    if (((!min && min !== 0) || (!max && max !== 0)) || (min === 0 && max === 0)) {
        return null;
    }

    return (min === max) ? min : `${min} - ${max}`;
}

export const getDeliveryPriceDescription = delivery => {
    let result = '';
    const { price = [], address = [], addressDiscount = [] } = delivery;

    if (address?.length) {
        const sortedPrices = [...address].sort((a, b) => b.price - a.price);
        sortedPrices.forEach(item => {
            switch (item.delivery_type) {
                case DELIVERY_TYPE.DOOR:
                    result += item.price > 0
                        ? `Доставка до двери - <b>${item.price}р</b><br />`
                        : 'Доставка до двери - <b>бесплатно</b><br />';
                    break;
                case DELIVERY_TYPE.MEETING_POINT:
                    result += item.price > 0
                        ? `Доставка в точку встречи - <b>${item.price}р</b><br />`
                        : 'Доставка в точку встречи - <b>бесплатно</b><br />';
                    break;
                case DELIVERY_TYPE.WITHOUT:
                    result += item.price > 0
                        ? `Без доставки - <b>${item.price}р</b><br />`
                        : 'Без доставки - <b>бесплатно</b><br />';
                    break;
            }
        });
        result = result?.length ? result + '<br /><br />' : result;
    }
    if (addressDiscount?.length) {
        addressDiscount.forEach(item => {
            switch (item.delivery_type) {
                case DELIVERY_TYPE.DOOR:
                    result = item?.free
                        ? 'Полностью бесплатная доставка при заказе <strong>до двери</strong><br />'
                        : '';
                    break;
                case DELIVERY_TYPE.MEETING_POINT:
                    result = item?.free
                        ? 'Полностью бесплатная доставка при заказе <strong>в точку встречи</strong><br />'
                        : '';
                    break;
                case DELIVERY_TYPE.WITHOUT:
                    result = item?.free
                        ? 'Полностью бесплатная доставка при заказе <strong>без доставки</strong><br />'
                        : '';
                    break;
            }
        });
        result = result?.length ? result + '<br /><br />' : result;
    }

    if (price?.length) {
        const sortedPrices = [...price].sort((a, b) => b.price - a.price);
        sortedPrices.forEach(item => {
            result += item.price > 0
                ? `Заказ на сумму от ${item.from}р - <b>${item.price}р</b><br />`
                : `Заказ на сумму от ${item.from}р - <b>бесплатно</b><br />`;
        });
    }

    return result;
};

export const getDeliveryByType = ({ services = [], orderPrice = 0, free_delivery = false }) => {
    const FREE = 'бесплатно';

    const result = {
        priceList: {
            [DELIVERY_TYPE.DOOR]: {
                price: null,
                priceWithoutDiscount: 0,
                text: FREE,
                subtext: ''
            },
            [DELIVERY_TYPE.MEETING_POINT]: {
                price: null,
                priceWithoutDiscount: 0,
                text: FREE,
                subtext: ''
            },
            [DELIVERY_TYPE.WITHOUT]: {
                price: null,
                priceWithoutDiscount: 0,
                text: FREE,
                subtext: ''
            },
            [DELIVERY_TYPE.COLLECT]: {
                price: null,
                priceWithoutDiscount: 0,
                text: FREE,
                subtext: ''
            },
        },
        useAddressDiscount: false
    };

    if (services?.length) {
        services.forEach(service => {
            let deliveryPriceByPrice = 0;
            const price = service?.delivery?.price;
            const address = service?.delivery?.address;
            // const addressDiscount = service?.delivery?.addressDiscount;
            
            if (price?.length) {
                const filteredOptions = price
                    .filter(({ from = 0 }) => from <= orderPrice)
                    .sort((a, b) => a.from - b.from);
        
                const closerOption = filteredOptions[filteredOptions.length - 1];
                if (closerOption) {
                    deliveryPriceByPrice += closerOption.price;
                }
            }

            if (address?.length) {
                result.priceList[DELIVERY_TYPE.DOOR].price = 0;
                result.priceList[DELIVERY_TYPE.MEETING_POINT].price = 0;
                result.priceList[DELIVERY_TYPE.WITHOUT].price = 0;
                result.priceList[DELIVERY_TYPE.COLLECT].price = 0;

                address.forEach(item => {
                    const price = +item.price ? +item.price : null;
                    const showSubtext = price > 0;

                    if(item.free || free_delivery) {
                        if (service.promotion && service.promotion.delivery_type === item.delivery_type) {
                            result.priceList[item.delivery_type].price = 0;
                            result.priceList[item.delivery_type].priceWithoutDiscount += price;
                            result.priceList[item.delivery_type].text = `скидка ${service.promotion.discount}%`;
                            result.priceList[item.delivery_type].subtext = `скидка ${service.promotion.discount}%`;
                        } else {
                            result.priceList[item.delivery_type].price = 0;
                            result.priceList[item.delivery_type].priceWithoutDiscount += price;
                            result.priceList[item.delivery_type].text = FREE;
                            result.priceList[item.delivery_type].subtext = '';
                        }
                    } else {
                        switch (item.delivery_type) {
                            case DELIVERY_TYPE.DOOR:
                                result.priceList[DELIVERY_TYPE.DOOR].price += price;
                                result.priceList[DELIVERY_TYPE.DOOR].priceWithoutDiscount += price;
                                result.priceList[DELIVERY_TYPE.DOOR].text = (price + deliveryPriceByPrice) > 0 ? `${price + deliveryPriceByPrice} Р` : FREE;
                                if (showSubtext) {
                                    result.priceList[DELIVERY_TYPE.DOOR].subtext += `+ ${price} Р за доставку до двери`;
                                }
                                if (service.promotion && service.promotion.delivery_type === item.delivery_type) {
                                    result.priceList[DELIVERY_TYPE.DOOR].price = 0;
                                    result.priceList[DELIVERY_TYPE.DOOR].text = `скидка ${service.promotion.discount}%`;
                                    result.priceList[DELIVERY_TYPE.DOOR].subtext = `скидка ${service.promotion.discount}%`;
                                }
                                break;
                            case DELIVERY_TYPE.MEETING_POINT:
                                result.priceList[DELIVERY_TYPE.MEETING_POINT].price += price;
                                result.priceList[DELIVERY_TYPE.MEETING_POINT].priceWithoutDiscount += price;
                                result.priceList[DELIVERY_TYPE.MEETING_POINT].text = (price + deliveryPriceByPrice) > 0 ? `${price + deliveryPriceByPrice} Р` : FREE;
                                if (showSubtext) {
                                    result.priceList[DELIVERY_TYPE.MEETING_POINT].subtext += `+ ${price} Р за доставку в точку встречи`;
                                }
                                if (service.promotion && service.promotion.delivery_type === item.delivery_type) {
                                    result.priceList[DELIVERY_TYPE.MEETING_POINT].price = 0;
                                    result.priceList[DELIVERY_TYPE.MEETING_POINT].text = `скидка ${service.promotion.discount}%`;
                                    result.priceList[DELIVERY_TYPE.MEETING_POINT].subtext = `скидка ${service.promotion.discount}%`;
                                }
                                break;
                            case DELIVERY_TYPE.WITHOUT:
                                result.priceList[DELIVERY_TYPE.WITHOUT].price += price;
                                result.priceList[DELIVERY_TYPE.WITHOUT].priceWithoutDiscount += price;
                                result.priceList[DELIVERY_TYPE.WITHOUT].text = (price + deliveryPriceByPrice) > 0 ? `${price + deliveryPriceByPrice} Р` : FREE;
                                if (showSubtext) {
                                    result.priceList[DELIVERY_TYPE.WITHOUT].subtext += `+ ${price} Р`;
                                }
                                if (service.promotion && service.promotion.delivery_type === item.delivery_type) {
                                    result.priceList[DELIVERY_TYPE.WITHOUT].price = 0;
                                    result.priceList[DELIVERY_TYPE.WITHOUT].text = `скидка ${service.promotion.discount}%`;
                                    result.priceList[DELIVERY_TYPE.WITHOUT].subtext = `скидка ${service.promotion.discount}%`;
                                }
                                break;
                            case DELIVERY_TYPE.COLLECT:
                                result.priceList[DELIVERY_TYPE.COLLECT].price += price;
                                result.priceList[DELIVERY_TYPE.COLLECT].priceWithoutDiscount += price;
                                result.priceList[DELIVERY_TYPE.COLLECT].text = (price + deliveryPriceByPrice) > 0 ? `${price + deliveryPriceByPrice} Р` : FREE;
                                if (showSubtext) {
                                    result.priceList[DELIVERY_TYPE.COLLECT].subtext += `+ ${price} Р`;
                                }
                                if (service.promotion && service.promotion.delivery_type === item.delivery_type) {
                                    result.priceList[DELIVERY_TYPE.COLLECT].price = 0;
                                    result.priceList[DELIVERY_TYPE.COLLECT].text = `скидка ${service.promotion.discount}%`;
                                    result.priceList[DELIVERY_TYPE.COLLECT].subtext = `скидка ${service.promotion.discount}%`;
                                }
                                break;
                        }
                    }
                });
            }
        });
    }

    return result;
}

const formatUserId = (id, phone) => {
    let userId = phone.substr(-10);
    userId = id + userId;
    while(userId.length < 16) {
        userId = '0' + userId;
    }
    return userId;
}
const sendAppMetricaEvent = async (event, data = {}) => {
    const appMetricaActivated = await indexedDB.get('mircity-appMetricaACtivated');
    data = {
        ...data,
        Auth: store.state.auth.user?.id && store.state.auth.user?.phone ? 1 : 0,
        UserId: store.state.auth.user?.id && store.state.auth.user?.phone ? formatUserId(store.state.auth.user?.id, store.state.auth.user?.phone) : '',
        RegDate: store.state.auth.user?.regDate ? moment(store.state.auth.user?.regDate).format('YYYYMMDD') : 0
    };
    try {
        if(window.appMetrica && appMetricaActivated && process.env.VUE_APP_YANDEX_APPMETRICA_API_KEY != '') {
            window.appMetrica.reportEvent(event, data);
        }
    } catch(e) {}
    
    try {
        if(window.FirebasePlugin) {
            window.FirebasePlugin.logEvent(event, data);
        }
    } catch(e) {}

    // f7.dialog.create({
    //     title: event,
    //     text: JSON.stringify(data, null, 2).slice(0, 1000),
    //     buttons: [
    //         {
    //             text: 'Закрыть'
    //         }
    //     ]
    // }).open();
}

const sendAppsFlyerEvent = async (name, data = {}) => {
    // if(window.plugins && window.plugins.appsFlyer) {
    //     window.plugins.appsFlyer.logEvent(name, data, null, null);
    // }
}

export { sendAppMetricaEvent, sendAppsFlyerEvent };

export const checkActiveSurvey = async ({ this_ }) => {
    if (Device.cordova) {
        try {
            await store.dispatch('surveys/fetch');
            const survey = store.state.surveys.active;
            if(survey && survey.id) {
                this_.$root.$emit('surveyShowPreview', survey);
            }
        } catch (e) {
            store.commit('surveys/SET_LOADED');
        }
    } else {
        store.commit('surveys/SET_LOADED');
    }
}
