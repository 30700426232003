import { Device } from 'framework7';
import indexedDB from '../../plugins/indexedDB';
import { getDeviceId } from './service-helpers';
const { version } = require('../../../package.json');


export const axiosConfig = async config => {
    const deviceId = await getDeviceId();
    if(deviceId) {
        config.headers['Authorization'] = `Bearer ${deviceId}`;
    } else {
        const token = await indexedDB.get('mircity-token');
        if (token && typeof token === 'string') {
            config.headers['Authorization'] = `Bearer ${token.replace(/"/g, '')}`;
        }
    }
    const info = [];
    try {
        info.push(new Date().toISOString());
        if(Device) {
            info.push((Device.cordova ? '' : 'Browser ') + Device.os + ' ' + Device.osVersion);
        }
        if(version) {
            info.push(version);
        }
        if(window?.plugins?.uniqueDeviceID) {
            info.push(await getDeviceId());
        }
        const user = await indexedDB.get('mircity-user');
        if(user && user.phone) {
            info.push('000' + user.phone.substring(2) + '000');
        }
    } catch(e) {}
    if(config.method === 'post') {
        try {
            config.data.set('v', version);
            config.data.set('uinfo', btoa(info.join(':::')));
        } catch(e) {
            config.data = {
                ...config.data,
                uinfo: btoa(info.join(':::'))
            }
        }
    }
    if(config.method === 'get') {
        config.params = {
            ...config.params,
            v: version,
            uinfo: btoa(info.join(':::'))
        }
    }
    return config;
}