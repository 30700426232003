import store from '../store';
import indexedDB from '../plugins/indexedDB';
import { getDeviceId } from '../js/utils/service-helpers';
import F7 from 'framework7';
import { FD_ORDER_TYPE, GD_ORDER_TYPE, LD_ORDER_TYPE, PD_ORDER_TYPE, RD_ORDER_TYPE } from '@/js/utils/vars';

const TRANSITION = 'f7-dive';

const RememberScrollYDecorator = function () {
    const getView = () => document.getElementById('framework7-root').querySelector('.view-main > .page-current > .page-content');

    if (!this.params) this.params = {};

    this.params.scrollY = 0;

    const saveScrollY = () => {
        const view = getView();
        if (!view) throw Error('view does not exist');

        this.params.scrollY = view.scrollTop;
    };

    const setScrollY = () => {
        try {
            const view = getView();
            document.querySelector
            if (!view) throw Error('view does not exist');

            view.scrollTop = this.params.scrollY;
        } catch (error) {
            if (error.message === 'view does not exist') {
                const eventName = 'DOMContentLoaded';

                const handler = () => {
                    setScrollY();
                    window.removeEventListener(eventName, handler)
                }

                window.addEventListener(eventName, handler);
            }
        }
    }

    if (this.beforeLeave) {
        this.beforeLeave = (...args) => {
            saveScrollY();
            this.beforeLeave(...args);
        }
    } else {
        this.beforeLeave = (from, to, next) => {
            saveScrollY();
            next();
        }
    }

    if (!this.on) this.on = {};

    if (this.on.pageAfterIn) {
        this.on.pageAfterIn = async (...args) => {
            this.on.pageAfterIn(...args)

            setScrollY();
        }
    } else {
        this.on.pageAfterIn = (...args) => void setScrollY();
    }

    return this;
}

const isMobile = screen.width <= 760 || (!F7.device.desktop && !F7.device.ipad);
let customHomeRoute, customHomeForceRoute;

if (process.env.VUE_APP_HOME_SERVICES === 'false') {
    customHomeRoute = {
        path: '/',
        name: 'home',
        redirect: '/restaurant'
    }
    customHomeForceRoute = {
        path: '/home',
        name: 'home-force',
        redirect: '/restaurant',
        options: {
            transition: 'f7-dive'
        }
    }
} else {
    customHomeRoute = {
        path: '/',
        name: 'home',
        asyncComponent: () => isMobile ? import('../pages/home.vue') : import('../pages/desktop/home.vue'),
        beforeEnter: [clearStore],
    }
    customHomeForceRoute = {
        path: '/home',
        name: 'home-force',
        asyncComponent: () => import('../pages/home.vue'),
        options: {
            transition: 'f7-dive'
        },
        beforeEnter: [clearStore],
    }
}

export default [
    customHomeRoute,
    customHomeForceRoute,
    // {
    //     path: '/new',
    //     name: 'home-new',
    //     asyncComponent: () => import('../pages/desktop/home-new.vue'),
    //     options: {
    //         transition: TRANSITION
    //     },
    // },
    {
        path: '/app',
        name: 'app-redirect',
        asyncComponent: () => import('../pages/home.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/agreement',
        name: 'agreement',
        asyncComponent: () => isMobile ? import('../pages/user/agreement.vue') : import('../pages/desktop/user/agreement.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/personal-information',
        name: 'personal-information',
        asyncComponent: () => isMobile ? import('../pages/user/personal-information.vue') : import('../pages/desktop/user/personal-information.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/login',
        name: 'login',
        asyncComponent: () => isMobile ? import('../pages/user/login.vue') : import('../pages/desktop/user/login.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [publicOnly],
    },
    {
        path: '/profile',
        name: 'profile',
        asyncComponent: () => import('../pages/user/profile.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/feedback',
        name: 'feedback',
        asyncComponent: () => isMobile ? import('../pages/user/feedback.vue') : import('../pages/desktop/user/feedback.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    // {
    //     path: '/searchd/:searchValue',
    //     name: 'search-desktop',
    //     component: SearchPageDesktop,
    // },
    {
        path: '/faq',
        name: 'faq',
        asyncComponent: () => import('../pages/user/faq.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/referal-program',
        name: 'referal-program',
        asyncComponent: () => isMobile ? import('../pages/user/referal-program.vue') : import('../pages/desktop/user/referal-program.vue'),
        beforeEnter: [checkAuth]
    },
    {
        path: '/search',
        name: 'search-all',
        asyncComponent: () => import('../pages/search/index.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/search/:entityType',
        name: 'search-in-entity-list',
        asyncComponent: () => import('../pages/search/index.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/search/:entityType/:entityCode',
        name: 'search-in-entity',
        asyncComponent: () => import('../pages/search/index.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/cart',
        name: 'cart',
        asyncComponent: () => isMobile ? import('../pages/order/cart.vue') : import('../pages/desktop/order/cart.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/favorites',
        name: 'favorites',
        asyncComponent: () => isMobile ? import('../pages/user/favorites.vue') : import('../pages/desktop/user/favorites.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/delivery-terms',
        name: 'delivery-terms',
        asyncComponent: () => import('../pages/order/delivery-terms.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/delivery',
        name: 'delivery',
        asyncComponent: () => isMobile ? import('../pages/order/delivery.vue') : import('../pages/desktop/order/delivery.vue'),
        beforeEnter: [checkAuth]
    },
    {
        path: '/payment/:orderType/:orderId',
        name: 'payment',
        asyncComponent: () => isMobile ? import('../pages/order/payment.vue') : import('../pages/desktop/order/payment.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    { 
        path: '/payment-status/:status',
        name: 'payment-status',
        asyncComponent: () => isMobile ? import('../pages/order/payment-status.vue') : import('../pages/desktop/order/payment-status.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/tips-status/:status',
        name: 'tips-status',
        asyncComponent: () => import('../pages/order/tips-status.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/tips/:orderType/:orderId/:amount',
        name: 'tips',
        asyncComponent: () => import('../pages/order/tips.vue'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/account',
        name: 'account',
        asyncComponent: () => import('../pages/user/account.vue'),
        options: {
            transition: TRANSITION
        },
    },
    RememberScrollYDecorator.call({
        path: '/restaurant',
        name: 'restaurant-list',
        asyncComponent: () => isMobile ? import('../pages/restaurant/list') : import('../pages/desktop/restaurant/list'),
        options: {
            transition: TRANSITION
        },
    }),
    {
        path: '/restaurant/banner/:id',
        name: 'restaurant-list-banner',
        asyncComponent: () => isMobile ? import('../pages/restaurant/banner-list') : import('../pages/desktop/restaurant/banner-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/restaurant/banner/products/:id',
        name: 'restaurant-list-products-banner',
        asyncComponent: () => import('../pages/restaurant/banner-dish-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/restaurant/:restaurantCode/:source',
        name: 'restaurant-detail',
        asyncComponent: () => isMobile ? import('../pages/restaurant/detail') : import('../pages/desktop/restaurant/detail'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/restaurant/:restaurantCode',
        name: 'restaurant-detail-2',
        asyncComponent: () => isMobile ? import('../pages/restaurant/detail') : import('../pages/desktop/restaurant/detail'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/restaurant/:restaurantCode/product/:productId',
        name: 'restaurant-product',
        asyncComponent: () => import('../pages/restaurant/product'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode',
        name: 'entity-list',
        asyncComponent: () => isMobile ? import('../pages/entity/list') : import('../pages/desktop/entity/list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/banner/:id',
        name: 'entity-list-banner',
        asyncComponent: () => import('../pages/entity/banner-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/banner/products/:id',
        name: 'entity-list-products-banner',
        asyncComponent: () => import('../pages/entity/banner-products-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/:entityCode',
        name: 'entity-detail',
        asyncComponent: () => isMobile ? import('../pages/entity/detail') : import('../pages/desktop/entity/detail'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/:entityCode/category',
        name: 'entity-category',
        asyncComponent: () => isMobile ? import('../pages/entity/category') : import('../pages/desktop/entity/category'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/:entityCode/category/:categoryId',
        name: 'entity-category-detail',
        asyncComponent: () => isMobile ? import('../pages/entity/detail') : import('../pages/desktop/entity/detail'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/entity/:entityTypeCode/:entityCode/product/:productId',
        name: 'entity-product',
        asyncComponent: () => import('../pages/entity/product'),
        options: {
            transition: TRANSITION
        },
    },
    RememberScrollYDecorator.call({
        path: '/grocery',
        name: 'grocery-list',
        asyncComponent: () => isMobile ? import('../pages/grocery/list') : import('../pages/desktop/grocery/list'),
        options: {
            transition: TRANSITION
        },
    }),
    {
        path: '/grocery/banner/:id',
        name: 'grocery-list-banner',
        asyncComponent: () => isMobile ? import('../pages/grocery/banner-list') : import('../pages/desktop/grocery/banner-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/grocery/banner/products/:id',
        name: 'grocery-list-products-banner',
        asyncComponent: () => import('../pages/grocery/banner-products-list'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/grocery/:grocerCode',
        name: 'grocery-detail',
        asyncComponent: () => isMobile ? import('../pages/grocery/detail') : import('../pages/desktop/grocery/detail'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/grocery/:grocerCode/categories/:categoryId',
        name: 'grocery-category',
        asyncComponent: () => isMobile ? import('../pages/grocery/category') : import('../pages/desktop/grocery/category'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/grocery/:grocerCode/detail',
        name: 'grocery-category-detail',
        asyncComponent: () => import('../pages/grocery/category'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/grocery/:grocerCode/product/:productId',
        name: 'grocery-product',
        asyncComponent: () => import('../pages/grocery/product'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/orders',
        name: 'orders-list',
        asyncComponent: () => isMobile ? import('../pages/orders/list') : import('../pages/desktop/orders/list'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/orders/repeat',
        name: 'orders-repeat',
        asyncComponent: () => import('../pages/orders/repeat'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/orders/:orderId',
        name: 'orders-detail',
        asyncComponent: () => import('../pages/orders/detail'),
        options: {
            transition: TRANSITION
        },
        beforeEnter: [checkAuth]
    },
    {
        path: '/error/:status',
        name: 'error',
        asyncComponent: () => import('../pages/error.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/promo_05_08_2022',
        name: 'promo_05_08_2022',
        asyncComponent: () => isMobile ? import('../pages/promos/promo-05_08_2022') : import('../pages/desktop/promos/promo-05_08_2022'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/promo_11_12_2022',
        name: 'promo_11_12_2022',
        asyncComponent: () => isMobile ? import('../pages/promos/promo-11_12_2022') : import('../pages/desktop/promos/promo-11_12_2022'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '/promo/:url',
        name: 'promo',
        asyncComponent: () => isMobile ? import('../pages/promos/promo.vue') : import('../pages/desktop/promos/promo.vue'),
        options: {
            transition: TRANSITION
        },
    },
    {
        path: '(.*)',
        name: 'not-found',
        asyncComponent: () => import('../pages/404.vue'),
        options: {
            transition: TRANSITION
        },
    },
]

async function checkPayment(to, from, resolve, reject) {
    const router = this;
    const previousRoute = await indexedDB.get('previousRoute');
    if(!previousRoute){
        return resolve()
    }
    reject()
    router.navigate(previousRoute)
}


async function checkAuth(to, from, resolve, reject) {
    const router = this;
    await indexedDB.set('mircity-loginFromPath', from.path);
    if (!(store.state.auth.user && store.state.auth.user.phone)) {
        if(await getDeviceId()) {
            await store.dispatch('auth/getProfile', { onlyCheck: false });
        } else {
            await store.commit('auth/SET_TOKEN', await indexedDB.get('mircity-token') || null);
            await store.commit('auth/SET_USER', await indexedDB.get('mircity-user') || {});
        }
    }
    if (store.state.auth.user && store.state.auth.user.phone) {
        return resolve();
    }
    reject();

    router.navigate('/login', {
        props: {
            from: from.path
        }
    });
}

function publicOnly(to, form, resolve, reject) {
    if (store.state.auth.user && store.state.auth.user.phone) {
        return reject()
    }

    resolve()
}

function clearStore(to, form, resolve, reject) {
    try {
        store.dispatch('adsPlaces/clear');
        store.dispatch('banners/clear');
        // Store.commit('restaurants/CLEAR_ENTITIES');
        store.commit('restaurants/CLEAR_CATEGORIES');
        store.commit('restaurants/CLEAR_PRODUCTS');
        // store.commit('grocers/CLEAR_ENTITIES');
        store.commit('grocers/CLEAR_CATEGORIES');
        store.commit('grocers/CLEAR_PRODUCTS');
        store.commit('entities/CLEAR_ENTITIES');
        store.commit('entities/CLEAR_CATEGORIES');
        store.commit('entities/CLEAR_PRODUCTS');
    } catch(e) {}
    
    resolve()
}
