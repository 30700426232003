import axios from '../../plugins/axios';
import indexedDB from '../../plugins/indexedDB';

export default {
    namespaced: true,

    state: {
        request: null,
        adsPlace: {},
        adsPlaces: []
    },
    mutations: {
        SET_ADS_PAGE(state, payload) {
            state.adsPlace = payload;
        },
        SET_ADS_PAGES(state, payload) {
            state.adsPlaces = payload;
        },
        CLEAR_ADS_PAGES(state, payload) {
            state.adsPlaces = [];
        },
    },
    actions: {
        clear({ state, commit } = {}) {
            if(state.request) {
                state.request.abort();
            }
            commit('CLEAR_ADS_PAGES')
        },
        async getById({ commit }, { id }) {
            const { data } = await axios.get(`/front/aplaces/id/${ id }`);
            commit('SET_ADS_PAGE', data);
        },
        async getByRoute({ state, commit }, { routeName }) {
            if(state.request) {
                state.request.abort();
            }
            state.request = new AbortController();
            const { data } = await axios.get(`/front/aplaces/list/${ routeName }`, { signal: state.request.signal });
            state.request = null;

            const routeNamesMap = {
                'restaurant-list': 'restaurants',
                'grocery-list': 'grocers',
            };

            let adsPlacesIndexes = await indexedDB.get('mircity-adsPlacesIndexes');
            if(!adsPlacesIndexes) {
                adsPlacesIndexes = {};
                for(let i = 0; i < data.length; i++) {
                    adsPlacesIndexes[data[i].id] = -1;
                }
            }
            for(let i = 0; i < data.length; i++) {
                adsPlacesIndexes[data[i].id] = adsPlacesIndexes[data[i].id] + 1 < data[i].list.length ? adsPlacesIndexes[data[i].id] + 1 : 0;
                const { data: entity } = await axios.get(`/front/${ routeNamesMap[routeName] ? routeNamesMap[routeName] : 'entities/get' }/${ data[i].list[adsPlacesIndexes[data[i].id]] }`);
                data[i].entity = entity;
            }
            await indexedDB.set('mircity-adsPlacesIndexes', adsPlacesIndexes);

            commit('SET_ADS_PAGES', data);
        },
    },
}
