let observer = {
    disconnect: () => null,
    observe: () => null,
};

export default {
	bind(el, binding, vnode) {
		el.style.position = 'sticky';
		el.style.top = '70px';

		observer = new IntersectionObserver(([e]) => {
			const component = vnode.componentInstance;

			if (!component) throw new Error('it is not Vue instance, events will not be available');
			// if (!e.intersectionRatio > 0) return;

			component.$emit('toggle-stuck');
			// component.$emit(e.intersectionRect.y ? 'unstuck': 'stuck');
		}, { rootMargin: '-107px 0px 0px 0px', threshold: 0.05 });

		observer.observe(el);
	},
	unbind() {
		observer.disconnect();
	}
};
