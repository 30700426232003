<template>
    <f7-sheet
        class="review_app-dialog-sheet"
        bottom
        close-by-outside-click
        swipe-to-close
        backdrop
        @sheet:closed="closeOrder"
    >
        <div class="review_app-dialog-sheet__inner">
            <div class="review_app-dialog-sheet__title">
                <h2>Оцените приложение</h2>
            </div>
            <div class="review_app-dialog-sheet__rate">
                <button
                    v-for="index in 5"
                    :key="index"
                    @click.stop="updateRating(index)"
                >
                    <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M20.0058 1.87373C20.6261 0.650054 22.3739 0.650054 22.9942 1.87373L27.9571 11.6638C28.3225 12.3845 29.0121 12.8856 29.8104 13.0103L40.655 14.7051C42.0105 14.9169 42.5506 16.5791 41.5785 17.5472L33.8012 25.2925C33.2286 25.8627 32.9652 26.6734 33.0933 27.4712L34.8326 38.3087C35.05 39.6633 33.6361 40.6906 32.415 40.0653L22.6454 35.0621C21.9262 34.6937 21.0738 34.6937 20.3546 35.0621L10.585 40.0653C9.36389 40.6906 7.94995 39.6633 8.16736 38.3087L9.90672 27.4712C10.0348 26.6734 9.77135 25.8627 9.19882 25.2925L1.42152 17.5472C0.449428 16.5791 0.989505 14.9169 2.34498 14.7051L13.1896 13.0103C13.9879 12.8856 14.6775 12.3845 15.0429 11.6638L20.0058 1.87373Z" 
                            :fill="rating >= index ? 'url(#paint0_linear_2192_11604)' : '#E4E4E4'" 
                        />
                        <defs>
                            <linearGradient id="paint0_linear_2192_11604" x1="-3" y1="22.5" x2="46" y2="22.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#E65C00"/>
                                <stop offset="1" stop-color="#F9D423"/>
                            </linearGradient>
                        </defs>
                    </svg>

                </button>
            </div>
            <div
                v-if="openComment" 
                class="review_app_comment_title">
                <h2>Что именно вам не нравится?</h2>
            </div>
            <div
                v-if="openComment" 
                class="review_app_comment_input input" 
                :class="{'input-label__show': comment }">
                <div>
                    <textarea
                        type="text"
                        style="min-height: 100px;"
                        :value="comment"
                        placeholder="Напишите здесь"
                        @input="comment = $event.target.value"></textarea>
                </div>
            </div>
            <div class="review_app-dialog-sheet__details">
                <button
                    v-if="rating > 0"
                    :style="{ background }"
                    :class="`review_app-dialog-custom-button gradient-${background}`"
                    @click.stop="donereview_app"
                >
                    Оценить
                </button>
                <button
                    v-else
                    :style="{ background }"
                    :class="`review_app-dialog-custom-button`"
                >
                    Оценить
                </button>
            </div>
        </div>
        </div>
    </f7-sheet>
</template>

<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';

    import { ORDER_STATUS, ORDER_TYPE, BUSINESS_TYPE_MAP } from '../../js/utils/vars';
    import { goToExternalLink } from '../../js/utils/service-helpers';

    export default {
        name: 'ReviewAppDialog',

        props: {
            canOpen: {
                type: Boolean,
                default: false,
            }
        },
        
        data: () => ({
            BUSINESS_TYPE_MAP,
            order: {},
            rating: 0,
            tips: 0,
            waiting: false,
            background: '',
            inputError: false,
            tipsId: process.env.VUE_APP_CHACHACHAY_TIPS_DEFAULT_ID,
            openComment: false,
            comment: '',
        }),

        computed: {
            ...mapState('orders', ['entities']),
            ...mapState('style', ['colors', 'backgrounds']),
        },

        watch: {
            entities: {
                deep: true,
                async handler() {
                    const checkOpenreview_app = await indexedDB.get('mircity-review_appDialogOpen') || false;
                    for (let i = 0; i < this.entities.length; i++) {
                        const order = this.entities[i];
                        if (![
                            ORDER_STATUS.DONE, 
                            ORDER_STATUS.CANCELED, 
                            ORDER_STATUS.SUBMITTED, 
                            ORDER_STATUS.PAID].includes(order.status) && !checkOpenreview_app){
                                this.order = order;
                                this.waiting = true;
                                await this.openDialog();
                            }
                    }
                }
            },
            canOpen() {
                this.openDialog();
            }
        },

        async mounted() {
            await this.$store.dispatch('style/fetch');
            this.openComment = false;
        },

        methods: {
            async openDialog() {
                if (this.waiting && this.order?.id) {
                    if (this.order.type === ORDER_TYPE.ENTITY_DELIVERY) {
                        const code = this?.order?.ed?.items[0]?.entity?.entityType?.code;
                        if (code && this.backgrounds) {
                            this.background = this?.backgrounds[code] ? `${this?.backgrounds[code]} !important` : '';
                        }
                    } else {
                        this.background = BUSINESS_TYPE_MAP[this.order?.type];
                    }
                    if (this.order.concierge && this.order.concierge.tips_layout_id) {
                        this.tipsId = this.order.concierge.tips_layout_id;
                    }
                    this.$f7.sheet.open('.review_app-dialog-sheet');
                    this.waiting = false;
                    this.$emit('processing', true);
                    return;
                }
            },

            async donereview_app() {
                if (this.rating > 0 && this.rating <= 3 && !this.openComment) {
                    this.openComment = true;
                    return;
                }
                if (this.tips > 0) {
                    await this.pay();
                    return;
                }
                this.$f7.sheet.close();
                await indexedDB.set('mircity-review_appDialogOpen', true);
                
                goToExternalLink('https://redirect.appmetrica.yandex.com/serve/677058988306623735');
            },

            async closeOrder() {
                const review_app = {
                    order_id: this.order.id,
                    rating: this.rating,
                    tips: +this.tips || null,
                    text: this.comment,
                }
                await store.dispatch('orders/sendreview_app', { review_app });

                await indexedDB.set('mircity-review_appDialogOpen', true);

                this.$emit('processing', false);
                this.background = '';
                this.rating = 0;
                this.tips = 0;
                window.dispatchEvent(new Event('resize'));
            },

            updateRating(rating) {
                this.rating = rating === this.rating ? 0 : rating;
            },
        }
    };
</script>

<style lang="scss" scoped>
.review_app_comment_title {
    flex: 0 0 auto;
    h2 {
        margin-bottom: 16px;

        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        text-align: center;
    }
    h3 {
        margin-bottom: 25px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #707378;
    }
}
.review_app_comment_input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #F5F5F5;
        border: 1px solid #F5F5F5;
        z-index: 99;
        width: 311px;
        height: 72px;
        margin: auto;
        margin-bottom: 48px;

        > input:focus-within {
            border: 1px solid #90949A;
        }
        > input.input-focused {
            border: none;
        }

        &.input.input-focused {
            border: 1px solid #90949A;
        }
        &.input.input-success {
            border: 1px solid #219653;
        }
        &.input.input-with-errors {
            border: 1px solid #EB5757;
        }

        &__title {
            // padding: 10px;
            color: #23232180;
        }

        
}
.review_app-dialog-custom-button {
    height: 50px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    background-color: #EEEEEE;
    border-radius: 10px;

    width: 311px;
    margin: auto;
}
.review_app-dialog-sheet {
    height: auto;
    border-radius: 24px 24px 0 0;
    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__title {
        flex: 0 0 auto;
        h2 {
            margin-bottom: 24px;
            margin-top: 36px;

            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
        }
        h3 {
            margin-bottom: 25px;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: #707378;
        }

        &-pay {
            h2 {
                text-align: left;
                font-size: 20px;
                line-height: 23px;
            }
        }
    }
    &__rate {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 277px;
        margin: 0 auto 48px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__tips-users {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;

        padding-bottom: 15px;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        button {
            min-width: 92px;
            height: 50px;
            margin-top: 7px;

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(35, 35, 33, 0.12);
            border-radius: 36px;

            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }
            &:last-child {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    &__tips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;

        padding-bottom: 15px;
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        button {
            width: 92px;
            min-width: 92px;
            height: 50px;
            margin-right: 5px;

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(35, 35, 33, 0.12);
            border-radius: 36px;

            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }
            &:last-child {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    &__details {
        width: 100%;
        margin-bottom: 48px;
        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            i {
                font-size: 16px;
                &:first-child {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-left: auto;
                }
            }
        }
    }
    &__buttons {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        button, a {
            width: 100%;
            height: 43px;

            outline: 2px solid transparent;
            outline-offset: 2px;

            font-size: 13px;
            line-height: 15px;
    
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    &__input {
        margin: 10px 0;

        input {
            width: 100%;
            padding: 14px 20px 15px;

            border: 1px solid #EEEEEE;
            border-radius: 36px;

            font-size: 13px;
            line-height: 15px;
            color: #707378;
        }

        &-error {
            input {
                border: 1px solid #ff3b30;
            }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
     
        input[type="number"]:hover,
        input[type="number"]:focus {
            -moz-appearance: number-input;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
</style>
