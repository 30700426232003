import axios from '../../plugins/axios';
export default {
    namespaced: true,

    state: {
        dishList: [],
        dish: {},
    },
    mutations: {
        getDishes(state, restaurantId) {
            axios
                .get(
                    '/front/restaurants/' +
                    restaurantId +
                    '/dishes'
                )
                .then(response => {
                    state.dishList = response.data;
                    state.dishList.sort((a, b) => a.position - b.position);
                });
        },
        getDish(state, {
            restaurantId,
            dishId
        }) {
            axios
                .get(
                    '/front/restaurants/' +
                    restaurantId +
                    '/dishes/' +
                    dishId
                )
                .then(response => (state.dish = response.data));
        },
    },
    actions: {
        getDishes({
            commit
        }, {
            restaurantId
        } = {}) {
            commit('getDishes', restaurantId);
        },
        getDish({
            commit
        }, {
            restaurantId,
            dishId
        } = {}) {
            commit('getDish', {
                restaurantId,
                dishId
            });
        }
    },
}