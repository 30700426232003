import axios from 'axios';
import { ORDER_TYPE } from '../js/utils/vars';
import { axiosConfig } from '../js/utils/axios-config';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000
});

axiosInstance.interceptors.request.use(async config => {
    return await axiosConfig(config);
}, function (error) {
    return Promise.reject(error);
});

const getRestaurantsByIds = async (restaurantIds, includeSchedule = false) => {
    const params = { filterBy: JSON.stringify({ ids: restaurantIds, includeSchedule }) }
    const { data } = await axiosInstance.get('front/restaurant/items', { params })
    return data
}

const getEntitiesByIds = async (entitiesIds, includeSchedule = false) => {
    const params = { filterBy: JSON.stringify({ ids: entitiesIds, includeSchedule }) }
    const { data } = await axiosInstance.get('front/entities/items', { params })
    return data
}

const getGroceriesByIds = async (groceryIds, includeSchedule = false) => {
    const params = { filterBy: JSON.stringify({ ids: groceryIds, includeSchedule }) }
    const { data } = await axiosInstance.get('front/grocery/items', { params })
    return data
}

export const getServiceItemsByIds = async (ids, serviceType, includeSchedule) => {
    let serviceItems = []
    try {
        switch (serviceType){
        case ORDER_TYPE.RESTAURANT_DELIVERY:
            serviceItems = await getRestaurantsByIds(ids, includeSchedule)
            break
        case ORDER_TYPE.GROCER_DELIVERY:
            serviceItems = await getGroceriesByIds(ids, includeSchedule)
            break
        case ORDER_TYPE.ENTITY_DELIVERY:
            serviceItems = await getEntitiesByIds(ids, includeSchedule)
            break
        }
    } catch(e) {}

    return serviceItems
}
