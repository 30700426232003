<template>
    <f7-sheet
        class="push-message-sheet"
        top
        :backdrop="true"
        swipe-to-close
        close-by-backdrop-click
        close-by-outside-click
        @sheet:closed="closeModal()">
        <div @click="clickPushMessage">
            <div class="push-message-sheet__title">{{ sheet.title }}</div>
            <div class="push-message-sheet__text">{{ sheet.text }}</div>
            <div v-if="sheet.data.link" class="push-message-sheet__buttons">
                <button class="custom-button" @click.stop="$f7.sheet.close()">
                    Закрыть
                </button>
                <button class="custom-button push-message-sheet__button-submit">
                    Перейти
                </button>
            </div>
        </div>
    </f7-sheet>
</template>

<script>
    import { pushAction } from '@/js/utils/service-helpers.js';
    export default {
        name: 'PushMessageSheet',
        data: () => ({
            shown: false,
            sheet: {
                router: {},
                title: '',
                text: '',
                type: '',
                data: {},
            },
        }),
        mounted() {
            this.$root.$on(
                'pushMessageSheetShow',
                async ({ router, title, text, type, data }) => {
                    this.sheet = {
                        ...this.sheet,
                        ...{ router, title, text, type, data },
                    };
                    await new Promise(resolve => setTimeout(resolve, 200));
                    this.$f7.sheet.open('.push-message-sheet');
                    await new Promise(resolve => setTimeout(resolve, 10000));
                    this.$f7.sheet.close();
                }
            );
            this.$root.$on('pushMessageSheetHide', () => {
                this.$f7.sheet.close();
            });
        },
        methods: {
            clickPushMessage() {
                pushAction({ type: this.sheet.type, data: this.sheet.data });
                this.$f7.sheet.close();
            },
            closeModal() {
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style lang="scss">
.push-message-sheet {
    border-radius: 0 0 12px 12px;
    height: auto;

    .sheet-modal-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);

        & > div {
            padding: 16px;
        }
    }

    &__title {
        padding-bottom: 6px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        color: #707378;
    }
    &__text {
        font-size: 15px;
        line-height: 140%;
        color: #707378;
    }
    &__buttons {
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
            height: 32px;
        }
    }
    &__button-submit {
        background: #1f3a60;
        color: #ffffff;
    }
}
</style>
