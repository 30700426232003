import axios from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        types: [],
        entities: [],
        banners: [],
        popular: [],
        premium: [],
    },

    mutations: {
        SET_TYPES(state, payload) {
            state.types = payload ? payload.map(item => ({
                id: item.id, 
                code: item.code, 
                title: item.title, 
                active: false
            })) : [];
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload.map(item => ({ 
                id: item.id, 
                code: item.code, 
                image: item.picture, 
                title: item.title, 
                labels: item.labels || [], 
                categories_page: !!item.categories_page,
                delivery: item.delivery_time ? `${item.delivery_time}${item.max_delivery_time ? '-' + item.max_delivery_time : ''} мин` : '', 
                cart: item.min_price ? `от ${item.min_price} Р` : '',
                average_bill: item.average_bill !== null ? item.average_bill + 1 : null
            }));
        },
        SET_BANNERS(state, payload) {
            state.banners = payload.map(item => ({ 
                id: item.id, 
                code: item.code, 
                type: item.type,
                link: item.link,
                route_name: item.route_name,
                image: item.desktop_picture, 
                title: item.main_text,
                subtitle: item.description,
            }));
        },
        SET_POPULAR(state, payload) {
            state.popular = payload.map(item => ({ 
                id: item.id, 
                code: item.code, 
                type: item.type,
                link: item.link,
                route_name: item.route_name,
                image: item.picture, 
                title: item.title
            }));
        },
        SET_PREMIUM(state, payload) {
            state.premium = payload.map(item => ({ 
                id: item.id, 
                code: item.code, 
                image: item.picture, 
                title: item.title, 
                labels: item.labels || [], 
                delivery: item.delivery_time ? `${item.delivery_time}${item.max_delivery_time ? '-' + item.max_delivery_time : ''} мин` : '', 
                cart: item.min_price ? `от ${item.min_price} Р` : '',
                average_bill: item.average_bill !== null ? item.average_bill + 1 : null
            }));
        },
    },
    actions: {
        async fetchTypes({ commit }) {
            const { data } = await axios.get('/front-new/entities/types');
            commit('SET_TYPES', data);
        },
        async fetchEntities({ commit }, { code }) {
            const { data } = await axios.get(`/front-new/entities/get-by-code/${code}`);
            commit('SET_ENTITIES', data);
        },
        async fetchBanners({ commit }) {
            const { data } = await axios.get('/front-new/main-page/banners');
            commit('SET_BANNERS', data);
        },
        async fetchPopular({ commit }) {
            const { data } = await axios.get('/front-new/main-page/popular');
            commit('SET_POPULAR', data);
        },
        async fetchPremium({ commit }) {
            const { data } = await axios.get('/front-new/main-page/premium');
            commit('SET_PREMIUM', data);
        },
    }
};
