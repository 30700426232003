import axios from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        list: [],
        listDetails: []
    },

    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_LIST_DETAILS(state, payload) {
            state.listDetails = payload;
        }
    },
    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/customer/favorite');
            commit('SET_LIST', data);
        },
        async fetchDetails({ commit }) {
            const { data } = await axios.get('/front/customer/favorite/details');
            commit('SET_LIST_DETAILS', data);
        },
        async create({ dispatch }, { service_type, entity_id, product_id }) {
            await axios.post('/front/customer/favorite', { service_type, entity_id, product_id });
        },
        async delete({ dispatch }, { id }) {
            await axios.delete(`/front/customer/favorite/${ id }`);
        }
    }
};
