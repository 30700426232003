import sticky from './sticky';


const directivesMap = Object.freeze({
  sticky
});

export const directivesList = Object.values(directivesMap);

export default directivesMap;
