import axios from '@/plugins/axios'

export const namespaced = true;

export const state = () => ({
    colors: {},
    backgrounds: {}
});

export const mutations = {
    SET_COLORS(state, payload) {
        state.colors = payload;
    },
    SET_BACKGROUNDS(state, payload) {
        state.backgrounds = payload;
    },
};

export const actions = {
    async fetch({ commit }) {
        const { data } = await axios.get('/front/entities-style');
        commit('SET_COLORS', data.colors);
        commit('SET_BACKGROUNDS', data.backgrounds);
    },
};
