import axios from '@/plugins/axios';


export default {
    namespaced: true,
    state: {
        settings: {},
    },

    mutations: {
        SET_SETTINGS(state, payload) {
            state.settings = payload;
        },
    },

    actions: {
        async getSettings({ commit }) {
            const { data } = await axios.get('/front/feedback/settings');
            commit('SET_SETTINGS', data.value);
        },
        async send(ctx, payload) {
            const { data } = await axios.post('/front/feedback/send', payload);
            return data;
        },
    }
}
