import axios from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        entities: [],
        active: null,
        loaded: false
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
            state.active = payload.length ? payload[0] : null;
            state.loaded = true;
        },
        SET_LOADED(state) {
            state.loaded = true;
        }
    },
    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/surveys');
            commit('SET_ENTITIES', data);
        },
        async saveResult({ commit, dispatch }, { results }) {
            await axios.post('/front/surveys/results', { results });
            dispatch('fetch');
        }
    }
};
