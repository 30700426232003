import { updatePushToken } from '../../js/utils/service-helpers.js';
import indexedDB from '../../plugins/indexedDB';
import axios from '@/plugins/axios';
import { getDeviceId } from '../../js/utils/service-helpers';

export default {
    namespaced: true,

    state: {
        user: {},
        token: null,
        adult: false,
        need_agree: false,
    },
    mutations: {
        async RESTORE_USER(state) {
            state.user = await indexedDB.get('mircity-user') || {};
        },
        async SET_TOKEN(state, payload) {
            state.token = payload;
            if(payload !== null) {
                await indexedDB.set('mircity-token', payload);
            }
        },
        async SET_USER(state, payload) {
            state.user = payload;
            if(payload && payload.phone) {
                await indexedDB.set('mircity-user', payload);
            }
        },
        async CLEAR_ALL(state) {
            state.user = {};
            state.token = null;
    
            await indexedDB.remove('mircity-user');
            await indexedDB.remove('mircity-token');
        }
    },
    actions: {
        async setToken({ commit }, { token }) {
            await commit('SET_TOKEN', token);
            await updatePushToken(true);
        },
        async getUserFromStorage() {
            return await indexedDB.get('mircity-user') || {};
        },
        async getTokenFromLocalStorage() {
            return await indexedDB.get('mircity-token') || null;
        },
        async getProfile({ commit }, { onlyCheck = false }) {
            const { data } = await axios.post('/front/customer/profile', { onlyCheck });
            if(data) {
                await commit('SET_USER', data);
            }
        },
        async setAdult({ state, commit }) {
            state.user.adult = true;
            state.adult = true;
            if(state.user.id) {
                await axios.post('/front/customer/set-adult');
            }
        },
        async checkAgreementVersion({ state, commit }) {
            if(state.user.id) {
                const { data } = await axios.get('/front/customer/check-agreement-version');
                state.user.need_agree = data.need_agree;
            }
        },
        async setAgreementVersion({ state, commit }) {
            if(state.user.id) {
                await axios.post('/front/customer/set-agreement-version');
            }
        },
        async logout({ commit }) {
            await commit('CLEAR_ALL');
            const device_id = await getDeviceId();
            await axios.post('/front/customer/logout', { device_id });
        },
        async remove({ commit }) {
            await commit('CLEAR_ALL');
            const device_id = await getDeviceId();
            await axios.post('/front/customer/remove', { device_id });
        }
    },
}