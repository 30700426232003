import axios from '@/plugins/axios';
import { getDeviceId } from '../../js/utils/service-helpers';

export default {
    namespaced: true,

    actions: {
        async requestPhoneValidation({}, { phone, name, captcha_code = '' } = {}) {
            let response = null;
            try {
                const { data } = await axios.post('/front/customer/register', { phone: '+7' + phone.replace(/[^\d]/g, '').slice(0, 10), name, captcha_code });
                response = data;
            } catch (e) {
                response = {
                    message: e.message
                };
            }
            return response;
        },

        async repeatRequest({}, { userInfo }) {
            let response = null;
            try {
                const { data } = await axios.post('/front/customer/register', { phone: userInfo.phone, name: userInfo.name });
                response = data;
            } catch (e) {
                response = {
                    message: e.message
                };
            }
            return response;
        },

        async validateSmsCode({}, { customer_id, code }) {
            let response = null;
            try {
                let resp = null;
                const device_id = await getDeviceId();
                if(device_id) {
                    resp = await axios.post('/front/customer/verification-device', { customer_id, device_id, code });
                } else {
                    resp = await axios.post('/front/customer/verification', { customer_id, code });
                }
                response = resp.data;
            } catch (e) {
                response = {
                    message: e.message
                };
            }
            return response;
        },
    },

}
