<template>
    <div v-show="shown" class="preloader__backdrop">
        <f7-preloader size="26" />
    </div>
</template>

<script>
    export default {
        name: 'Preloader',
        data: () => ({
            waitingShown: false,
            shown: false
        }),
        mounted() {
            let this_ = this;
            this_.$root.$on('preloaderShow', async () => {
                this_.waitingShown = true;
                // await new Promise(resolve => setTimeout(resolve, 200));
                if(this_.waitingShown)
                    this_.shown = true;
            });
            this_.$root.$on('preloaderHide', () => {
                this_.waitingShown = false;
                this_.shown = false;
            });
        },

    };
</script>

<style>
    .preloader__backdrop {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        z-index: 15000;
    }
</style>
