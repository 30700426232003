import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        adsCampaign: {},
    },
    mutations: {
        SET_PROMO(state, payload) {
            state.adsCampaign = payload;
        },
    },
    actions: {
        async getByRoute({ commit }, { routeName }) {
            const { data } = await axios.get(`/front/acampaigns/${ routeName }`);
            commit('SET_PROMO', data);
        },
    },
}
