<template>
    <f7-sheet
        class="agreement-version-sheet"
        backdrop
        style="height:auto; --f7-sheet-bg-color: #fff;"
        :swipe-to-close="false"
        :close-by-backdrop-click="false"
        :close-by-outside-click="false"
    >
        <div>
            <div class="agreement-version__title">Пользовательское соглашение обновилось</div>
            <f7-list class="agreement-version__list">
                <f7-list-item
                    class="checkbox-list__item checkbox-gradient-default"
                    checkbox
                    :checked="agreement"
                    @change="agreement = !agreement"
                >
                    <span class="agreement-version__list__label">
                        <a
                            href="#"
                            class="text-pink-600"
                            @click="goToAgreement">Согласен с обновлением пользовательского соглашения</a>
                    </span>
                </f7-list-item>
            </f7-list>
        </div>
        <div>
            <button
                :class="`custom-button agreement-version__button-submit`"
                :disabled="!agreement"
                @click.stop="setVersion"
            >Согласиться</button>
        </div>
    </f7-sheet>
</template>

<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import { Device } from 'framework7';

    export default {
        name: 'AgreementVersionSheet',
        data: () => ({
            shown: false,
            agreement: false
        }),
        computed: {
            ...mapState('auth', {
                user: state => state.user,
            }),
        },
        async mounted() {
            this.$root.$on('agreementVersionSheetShow', async () => {
                this.$f7.sheet.open('.agreement-version-sheet');
            });
            this.$root.$on('agreementVersionSheetHide', () => {
                this.$f7.sheet.close();
            });
        },
        methods: {
            async setVersion() {
                await store.dispatch('auth/setAgreementVersion');
                this.$f7.sheet.close();
            },
            goToAgreement() {
                this.$f7.views[0].router.navigate({ name: 'personal-information' });
                this.$f7.sheet.close();
            }
        },
    };
</script>

<style lang="scss">
.agreement-version {
    height: 340px;
    .sheet-modal-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
    }

    &__img {
        height: 50px;
        background: url('../../static/logo-icon.svg') center center no-repeat;
        background-size: contain;
        margin-top: 10px;
    }
    &__title {
        padding: 20px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #000000;
        text-align: center;
    }
    &__text {
        padding-right: 25px;
        margin: 10px 0 24px;
        font-size: 14px;
        line-height: 17px;
        color: #232321;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
        }
    }
    &__button-submit {
        background: #1f3a60;
        color: #ffffff;
        margin-bottom: 12px;
    }

    &__list {
        margin: 0 0 20px;

        ul:before,
        ul:after {
            display: none;
        }

        .item-content {
            width: 100%;
        }
        .item-inner {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 0 !important;
        }

        &__title {
            margin-bottom: 3px;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 130%;
            color: #929292;
        }

        &__label {
            font-size: 13px;
            line-height: 130%;
            color: #232321;
        }
    }
}
</style>
