<template>
    <div class="app-nav-bottom__content">
        <button
            class="flex flex-col items-center app-nav-bottom__content__item"
            :class="{'app-nav-bottom__content__item__active': activeMenu === 'services'}"
            @click="open('home-force')"
        >
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.4167 4.58333V6.41667H13.75V4.58333H17.4167ZM8.25 4.58333V10.0833H4.58333V4.58333H8.25ZM17.4167 11.9167V17.4167H13.75V11.9167H17.4167ZM8.25 15.5833V17.4167H4.58333V15.5833H8.25ZM19.25 2.75H11.9167V8.25H19.25V2.75ZM10.0833 2.75H2.75V11.9167H10.0833V2.75ZM19.25 10.0833H11.9167V19.25H19.25V10.0833ZM10.0833 13.75H2.75V19.25H10.0833V13.75Z"
                    class="app-nav-bottom__content__item"
                    :class="{'app-nav-bottom__content__item__active': activeMenu === 'services'}"
                />
            </svg>
            <span class="mt-2 text-xs">Все услуги</span>
        </button>
        <button
            class="flex flex-col items-center app-nav-bottom__content__item"
            :class="{'app-nav-bottom__content__item__active': activeMenu === 'cart'}"
            @click="open('cart')"
        >
            <f7-badge
                v-if="!isEmpty && count > 0"
                :class="{[`cart-bage gradient-${ BUSINESS_TYPE_MAP[orderType] }`]: true, 'cart-bage-big': cartBageBig }"
            >{{ count }}</f7-badge>
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.5833 16.5C16.0695 16.5 16.5358 16.6932 16.8797 17.037C17.2235 17.3808 17.4166 17.8471 17.4166 18.3333C17.4166 18.8196 17.2235 19.2859 16.8797 19.6297C16.5358 19.9735 16.0695 20.1667 15.5833 20.1667C14.5658 20.1667 13.75 19.3417 13.75 18.3333C13.75 17.3158 14.5658 16.5 15.5833 16.5ZM0.916626 1.83334H3.91413L4.77579 3.66668H18.3333C18.5764 3.66668 18.8096 3.76325 18.9815 3.93516C19.1534 4.10707 19.25 4.34023 19.25 4.58334C19.25 4.73918 19.2041 4.89501 19.14 5.04168L15.8583 10.9725C15.5466 11.5317 14.9416 11.9167 14.2541 11.9167H7.42496L6.59996 13.4108L6.57246 13.5208C6.57246 13.5816 6.5966 13.6399 6.63958 13.6829C6.68256 13.7259 6.74085 13.75 6.80163 13.75H17.4166V15.5833H6.41663C5.39913 15.5833 4.58329 14.7583 4.58329 13.75C4.58329 13.4292 4.66579 13.1267 4.80329 12.87L6.04996 10.6242L2.74996 3.66668H0.916626V1.83334ZM6.41663 16.5C6.90286 16.5 7.36917 16.6932 7.71299 17.037C8.05681 17.3808 8.24996 17.8471 8.24996 18.3333C8.24996 18.8196 8.05681 19.2859 7.71299 19.6297C7.36917 19.9735 6.90286 20.1667 6.41663 20.1667C5.39913 20.1667 4.58329 19.3417 4.58329 18.3333C4.58329 17.3158 5.39913 16.5 6.41663 16.5ZM14.6666 10.0833L17.215 5.50001H5.62829L7.79163 10.0833H14.6666Z"
                    class="app-nav-bottom__content__item"
                    :class="{'app-nav-bottom__content__item__active': activeMenu === 'cart'}"
                />
            </svg>
            <span class="mt-2 text-xs">Корзина</span>
        </button>
        <button
            class="flex flex-col items-center app-nav-bottom__content__item"
            :class="{'app-nav-bottom__content__item__active': activeMenu === 'favorites'}"
            @click="open('favorites')"
        >
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5954 5.59039C20.2825 4.85517 19.8313 4.18893 19.2672 3.62896C18.7025 3.06731 18.0369 2.62098 17.3063 2.31423C16.5487 1.99489 15.7362 1.83143 14.9159 1.83335C13.765 1.83335 12.6422 2.15315 11.6664 2.75721C11.433 2.90171 11.2112 3.06043 11.0011 3.23335C10.7911 3.06043 10.5693 2.90171 10.3358 2.75721C9.36008 2.15315 8.23725 1.83335 7.0864 1.83335C6.2577 1.83335 5.45468 1.99443 4.69601 2.31423C3.96301 2.62219 3.30238 3.06516 2.73513 3.62896C2.17021 4.1883 1.71893 4.8547 1.40687 5.59039C1.0824 6.35553 0.916656 7.16806 0.916656 8.00427C0.916656 8.7931 1.07539 9.6151 1.39053 10.4513C1.65432 11.1501 2.03249 11.875 2.5157 12.607C3.28138 13.7654 4.33418 14.9735 5.64143 16.1982C7.80772 18.2283 9.95301 19.6307 10.0441 19.6876L10.5973 20.0476C10.8424 20.2063 11.1575 20.2063 11.4027 20.0476L11.9559 19.6876C12.0469 19.6283 14.1899 18.2283 16.3585 16.1982C17.6658 14.9735 18.7186 13.7654 19.4843 12.607C19.9675 11.875 20.348 11.1501 20.6094 10.4513C20.9246 9.6151 21.0833 8.7931 21.0833 8.00427C21.0856 7.16806 20.9199 6.35553 20.5954 5.59039ZM11.0011 18.1738C11.0011 18.1738 2.69078 12.7704 2.69078 8.00427C2.69078 5.59038 4.65866 3.63369 7.0864 3.63369C8.79283 3.63369 10.2728 4.6002 11.0011 6.01204C11.7295 4.6002 13.2095 3.63369 14.9159 3.63369C17.3436 3.63369 19.3115 5.59038 19.3115 8.00427C19.3115 12.7704 11.0011 18.1738 11.0011 18.1738Z" fill="white" />
                <path d="M11.0011 18.1738C11.0011 18.1738 2.69078 12.7704 2.69078 8.00427C2.69078 5.59038 4.65866 3.63369 7.0864 3.63369C8.79283 3.63369 10.2728 4.6002 11.0011 6.01204C11.7295 4.6002 13.2095 3.63369 14.9159 3.63369C17.3436 3.63369 19.3115 5.59038 19.3115 8.00427C19.3115 12.7704 11.0011 18.1738 11.0011 18.1738Z" fill="white" />
                <path
                    d="M19.8301 6.09298C19.5421 5.42619 19.1269 4.82194 18.6076 4.31408C18.088 3.8047 17.4753 3.3999 16.8029 3.12169C16.1057 2.83207 15.3579 2.68382 14.6029 2.68556C13.5437 2.68556 12.5104 2.9756 11.6123 3.52345C11.3975 3.65451 11.1934 3.79845 11 3.95529C10.8066 3.79845 10.6025 3.65451 10.3877 3.52345C9.48965 2.9756 8.45625 2.68556 7.39707 2.68556C6.63437 2.68556 5.89531 2.83166 5.19707 3.12169C4.52246 3.40099 3.91445 3.80275 3.39238 4.31408C2.87245 4.82137 2.45712 5.42576 2.16992 6.09298C1.87129 6.78693 1.71875 7.52384 1.71875 8.28224C1.71875 8.99767 1.86484 9.74318 2.15488 10.5016C2.39766 11.1354 2.7457 11.7928 3.19043 12.4567C3.89512 13.5072 4.86406 14.6029 6.06719 15.7137C8.06094 17.5549 10.0354 18.8268 10.1191 18.8783L10.6283 19.2049C10.8539 19.3488 11.1439 19.3488 11.3695 19.2049L11.8787 18.8783C11.9625 18.8246 13.9348 17.5549 15.9307 15.7137C17.1338 14.6029 18.1027 13.5072 18.8074 12.4567C19.2521 11.7928 19.6023 11.1354 19.843 10.5016C20.133 9.74318 20.2791 8.99767 20.2791 8.28224C20.2812 7.52384 20.1287 6.78693 19.8301 6.09298ZM11 17.5055C11 17.5055 3.35156 12.6049 3.35156 8.28224C3.35156 6.09298 5.16269 4.31837 7.39707 4.31837C8.96758 4.31837 10.3297 5.19494 11 6.47541C11.6703 5.19494 13.0324 4.31837 14.6029 4.31837C16.8373 4.31837 18.6484 6.09298 18.6484 8.28224C18.6484 12.6049 11 17.5055 11 17.5055Z" 
                    class="app-nav-bottom__content__item"
                    :class="{'app-nav-bottom__content__item__active': activeMenu === 'favorites'}" />
            </svg>
            <span class="mt-2 text-xs">Избранное</span>
        </button>
        <button
            class="flex flex-col items-center app-nav-bottom__content__item"
            :class="{'app-nav-bottom__content__item__active': activeMenu === 'profile'}"
            @click="open('profile')"
        >
            <f7-badge
                v-if="!promocodeRead"
                class="cart-profile" />
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 3.66666C11.9724 3.66666 12.9051 4.05296 13.5927 4.7406C14.2803 5.42823 14.6666 6.36086 14.6666 7.33332C14.6666 8.30578 14.2803 9.23841 13.5927 9.92605C12.9051 10.6137 11.9724 11 11 11C10.0275 11 9.09487 10.6137 8.40723 9.92605C7.7196 9.23841 7.33329 8.30578 7.33329 7.33332C7.33329 6.36086 7.7196 5.42823 8.40723 4.7406C9.09487 4.05296 10.0275 3.66666 11 3.66666ZM11 5.49999C10.5137 5.49999 10.0474 5.69314 9.7036 6.03696C9.35978 6.38078 9.16663 6.84709 9.16663 7.33332C9.16663 7.81955 9.35978 8.28587 9.7036 8.62968C10.0474 8.9735 10.5137 9.16666 11 9.16666C11.4862 9.16666 11.9525 8.9735 12.2963 8.62968C12.6401 8.28587 12.8333 7.81955 12.8333 7.33332C12.8333 6.84709 12.6401 6.38078 12.2963 6.03696C11.9525 5.69314 11.4862 5.49999 11 5.49999ZM11 11.9167C13.4475 11.9167 18.3333 13.1358 18.3333 15.5833V18.3333H3.66663V15.5833C3.66663 13.1358 8.55246 11.9167 11 11.9167ZM11 13.6583C8.27746 13.6583 5.40829 14.9967 5.40829 15.5833V16.5917H16.5916V15.5833C16.5916 14.9967 13.7225 13.6583 11 13.6583Z"
                    class="app-nav-bottom__content__item"
                    :class="{'app-nav-bottom__content__item__active': activeMenu === 'profile'}"
                />
            </svg>
            <span class="mt-2 text-xs">Профиль</span>
        </button>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState, mapGetters } from 'vuex';

    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';
    import { BUSINESS_TYPE_MAP, ORDER_TYPE } from '@/js/utils/vars';

    export default {
        props: {
            activeMenu: {
                type: String,
                default: 'services'
            }
        },
        data: () => ({
            BUSINESS_TYPE_MAP,
            ORDER_TYPE,
            cartBageBig: false,
        }),
        computed: {
            ...mapState('cart', ['orderType', 'items']),
            ...mapGetters('cart', ['count', 'quantity', 'isEmpty']),
            ...mapState('style', ['colors', 'backgrounds']),
            ...mapState('entities', ['entity']),
            ...mapState('settings', {
                promocodeRead: state => state.promocode_read
            }),

            background() {
                if (this.orderType === ORDER_TYPE.ENTITY_DELIVERY) {
                    const code = this?.items[0]?.entity_type_code;
                    if (code && this.backgrounds) {
                        return {
                            background: this?.backgrounds[code] ? `${this?.backgrounds[code]} !important` : '',
                        }
                    }
                }
                return {}
            }
        },
        watch: {
            async quantity() {
                this.cartBageBig = true;
                await new Promise(resolve => setTimeout(resolve, 200));
                this.cartBageBig = false;
            }
        },
        async mounted() {
            if (store.state.auth.user && store.state.auth.user.phone) {
                await store.dispatch('settings/checkPromocodeRead');
                if(this.$root.checkPromocodeReadTimerId) {
                    clearInterval(this.$root.checkPromocodeReadTimerId);
                }
                this.$root.checkPromocodeReadTimerId = setInterval(async () => {
                    await store.dispatch('settings/checkPromocodeRead');
                }, 300000);
            }

            if (this.orderType === ORDER_TYPE.ENTITY_DELIVERY) {
                const code = this?.items[0]?.entity_type_code;
                if(code && !this.backgrounds[code]) {
                    await this.$store.dispatch('style/fetch');
                }
            }
        },
        methods: {
            open(name) {
                this.$root.$emit('statusDialogHidden');
                if(name === 'cart' && ['cart', 'delivery'].indexOf(this.$f7route.name) >= 0) {
                    return;
                }

                let menu = name;
                if(name === 'home-force') {
                    menu = 'all';
                }
                switch(this.$f7route.name) {
                case 'home':
                case 'home-force':
                    store.commit('surveys/SET_LOADED');
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'Main Page',
                        Page: '',
                        Menu: menu
                    });
                    break;
                case 'restaurant-list':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'List',
                        Page: 'restaurant',
                        Menu: menu
                    });
                    break;
                case 'restaurant-detail':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'Partner',
                        Page: this.$f7route.params.restaurantCode,
                        Menu: menu
                    });
                    break;
                case 'entity-list':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'List',
                        Page: this.$f7route.params.entityTypeCode,
                        Menu: menu
                    });
                    break;
                case 'entity-category':
                case 'entity-category-detail':
                case 'entity-detail':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'Partner',
                        Page: this.$f7route.params.entityCode,
                        Menu: menu
                    });
                    break;
                case 'grocery-list':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'List',
                        Page: 'grocery',
                        Menu: menu
                    });
                    break;
                case 'grocery-category':
                case 'grocery-category-detail':
                case 'grocery-detail':
                    sendAppMetricaEvent('MENU_CLICK', {
                        PageType: 'Partner',
                        Page: this.$f7route.params.grocerCode,
                        Menu: menu
                    });
                    break;
                }
                
                this.$f7router.navigate({ name });
            }
        }
    };
</script>

<style lang="scss">
.app-nav-bottom.toolbar.toolbar-bottom {
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    height: auto;
    box-shadow: 0px -11px 41px rgba(0, 0, 0, 0.06);
}
.app-nav-bottom__content__item {
    color: #999999;
    fill: #999999;
}
.app-nav-bottom__content__item__active {
    color: #000000;
    fill: #000000;
}
.app-nav-bottom__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70px;
    padding: 0 20px;
    background: #ffffff;
}
.cart-bage {
    position: absolute;
    background-image: linear-gradient(220.69deg, #de0043 4.26%, #7a001d 101.89%);
    margin-left: 14px;
    margin-top: -5px;
    border: 2px solid white;
    font-size: 10px;
    line-height: 11px;
    color: #333333;
    transform: scale(1);
    transition: transform .2s ease-in;
}
.cart-profile {
    position: absolute;
    background-color: green;
    margin-left: 14px;
    margin-top: -5px;
    border: 2px solid white;
    font-size: 10px;
    line-height: 11px;
    color: #333333;
    transform: scale(0.7);
    transition: transform .2s ease-in;
}
.cart-bage.cart-bage-big {
    transform: scale(1.5);
    transition: transform .2s ease-out;
}
</style>
