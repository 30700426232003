import axios from 'axios';
import store from '../store';
import indexedDB from './indexedDB';
import { f7 } from 'framework7-vue';
import { bus } from '../js/utils/bus';
import { axiosConfig } from '../js/utils/axios-config';


const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 40000
})

axiosInstance.interceptors.request.use(async config => {
    return await axiosConfig(config);
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    return Promise.reject(error);
});

export default axiosInstance;
