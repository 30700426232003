<template>
    <f7-popup
        class="force-update-popup"
        tablet-fullscreen
    >
        <div class="force-update-popup__content">
            <div>
                <div
                    v-if="force.title"
                    class="force-update-popup__title"
                    v-html="force.title">
                </div>
                <div
                    v-if="force.subtitle"
                    class="force-update-popup__subtitle"
                    v-html="force.subtitle">
                </div>
            </div>
            <div>
                <div v-if="force.picture" class="force-update-popup__img">
                    <img :src="imageSrc" />
                </div>
            </div>
            <div>
                <button
                    :class="`custom-button force-update-popup__button-submit`"
                    :style="buttonStyle"
                    @click.stop="goToUpdate"
                >{{ force.buttonText || 'Обновить приложение' }}</button>
            </div>
        </div>
    </f7-popup>
</template>

<script>
    import { Device } from 'framework7';
    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';
    export default {
        name: 'ForceUpdatePopup',
        data: () => ({
            shown: false,
            force: {
                minVersion: '',
                picture: '',
                title: '',
                subtitle: '',
                buttonText: '',
                buttonColor: '',
                buttonBackground: ''
            }
        }),
        computed: {
            imageSrc() {
                return this.force.picture ? `${process.env.VUE_APP_IMAGES_URL}/${ this.force.picture }` : '';
            },
            buttonStyle() {
                const style = [];
                if(this.force.buttonColor) {
                    style.push(`color: ${this.force.buttonColor};`);
                }
                if(this.force.buttonBackground) {
                    style.push(`background: ${this.force.buttonBackground};`);
                }
                return style.join(' ');
            }
        },
        mounted() {
            this.$root.$on('ForceUpdatePopupShow', async force => {
                this.force = {
                    ...this.force,
                    ...force
                }
                this.$f7.sheet.open('.force-update-popup');
                sendAppMetricaEvent('FORCE_UPDATE', {
                    Action: 'view'
                });
            });
        },
        methods: {
            goToUpdate() {
                sendAppMetricaEvent('FORCE_UPDATE', {
                    Action: 'click'
                });
                if(Device.ios) {
                    window.cordova.InAppBrowser.open(process.env.VUE_APP_APP_LINK_IOS, '_system', ['location=yes', 'hidden=yes'].join(','));
                } else {
                    window.cordova.InAppBrowser.open(process.env.VUE_APP_APP_LINK_ANDROID, '_system', ['location=yes', 'hidden=yes'].join(','));
                }
            },
        },
    };
</script>

<style lang="scss">
.force-update-popup {
    height: 100%;

    &__content {
        width: 100%;
        height: 100%;
        padding: 85px 30px 65px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #000000;
    }
    
    &__subtitle {
        margin-top: 16px;
        font-weight: 400;
        font-size: 15px;
        line-height: 145%;
        text-align: center;
        color: #4F4F4F;
    }

    &__img {
        img {
            width: 100%;
        }
    }
    
    &__buttons {
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
        }
    }
    &__button-submit {
        background: #1f3a60;
        color: #ffffff;
        margin-bottom: 12px;
    }
}
</style>
