<template>
    <Stories 
        v-show="shown"
        :stories="items"
        :duration="duration"
        @stories-end="storiesEnd" />
</template>

<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';
    import Stories from '@/components/common/Stories';

    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        components: { Stories },
        data: () => ({
            story: {},
            shown: false,
            items: []
        }),

        computed: {
            ...mapState('settings', {
                settings: state => state.settings.stories
            }),
            duration() {
                return this.story.show_time || 4000;
            }
        },

        async mounted() {
            const this_ = this;
            this_.$root.$on('storiesStart', async storyId => {
                
                if(storyId) {
                    if (this_.settings) {
                        for (const story of this_.settings) {
                            if (storyId === story.id) {
                                this.story = story;
                                this.story.show_time = story.show_time;
                            }
                        }
                    }
                } else {
                    const stories = await indexedDB.get('mircity-activeStories') || [];
                    if (this_.settings) {
                        for (const story of this_.settings) {
                            const checkStories = stories.find(a => a === story.id);
                            if (!checkStories) {
                                this_.story = story;
                                this.story.show_time = story.show_time;
                            }
                        }
                    }

                    if (this.story.id) {
                        stories.push(this_.story.id);
                        await indexedDB.set('mircity-activeStories', stories);
                    }
                }

                if (this_.story && this_.story.pictures) {
                    const item = {
                        username: 'MIRCITY',
                        picture: '',
                        time: '24h',
                        images: [],
                        button_name: '',
                        root_name: '',
                        color_button: '',
                        stories_name: '',
                    };
                    for (const picture of this_.story.pictures) {
                        const data = {
                            url: this_.imageSrc(picture.picture),
                            viewed: false,
                        }
                        item.images.push(data);
                    }   
                    item.button_name = this_.story.button_name;
                    item.root_name = this_.story.link || '/';
                    item.color_button = this_.story.color_button || '#fd7d89';
                    item.stories_name = this_.story.title;

                    if(item.images.length > 0 && !this_.shown) {
                        this_.items = [ item ];
                        this_.shown = true;
                        this_.$root.$emit('storiesShown');
                        this_.$root.$emit('commonStoriesStart');

                        sendAppMetricaEvent('BANNER_STORIES_OPEN', {
                            Action: 'view',
                            Stories: `${this.story.title ? this.story.title : this.story.id}`,
                        });
                    }
                }
            });
        },

        methods: {
            imageSrc(picture) {
                return `${process.env.VUE_APP_IMAGES_URL}/${ picture }`;
            },
            storiesEnd () {
                this.shown = false;
            }
        }
    };
</script>