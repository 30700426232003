<template>
    <div>
        <ReviewAppDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <ReviewDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <!-- <StatusDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        /> -->
        <RepeatDialog
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
        <PopupBanner
            :can-open="canOpen"
            @waiting="checkIfSheetOpen"
            @processing="waitProcessing"
        />
    </div>
</template>

<script>
    import store from '@/store';
    import ReviewDialog from '@/components/dialogs/ReviewDialog';
    import ReviewAppDialog from '@/components/dialogs/ReviewAppDialog';
    // import StatusDialog from '@/components/dialogs/StatusDialog';
    import RepeatDialog from '@/components/dialogs/RepeatDialog';
    import PopupBanner from '@/components/dialogs/PopupBanner';
    import indexedDB from '@/plugins/indexedDB';

    export default {
        name: 'StatusObserver',

        components: {
            ReviewDialog,
            // StatusDialog,
            RepeatDialog,
            PopupBanner,
            ReviewAppDialog
        },
        
        data: () => ({
            canOpen: false,
            inProcess: false,
        }),

        watch: {
            async inProcess() {
                await this.checkOrdersInWork();
            }
        },

        async mounted() {
            // ! Should be deleted after some weeks...
            await this.clearOldStorage();
            
            this.$root.$on('newOrderInWork', this.checkOrdersInWork);
            await this.checkOrdersInWork();

            // console.log('StatusObserver mounted');
        },

        methods: {
            async checkIfSheetOpen() {
                await this.timeout(2000);
                const sheet = this?.$f7?.sheet?.get();
                if (sheet?.opened) {
                    this.canOpen = false;
                    this.checkIfSheetOpen();
                    return;
                }

                this.canOpen = true;
                return;
            },

            async checkOrdersInWork() {
                if (this.inProcess) {
                    return;
                }
                await this.timeout(10000);

                let ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                try {
                    const ordersFromServer = await store.dispatch('orders/getByIds', { orderIds: ordersInWork.map(order => order?.id) });
                    
                    if(ordersFromServer && ordersFromServer.length > 0) {
                        ordersInWork = ordersInWork.filter(order => {
                            for(const item of ordersFromServer) {
                                if(item.id === order.id) {
                                    return true;
                                }
                            }
                            return false;
                        });
                        await indexedDB.set('mircity-ordersInWork', ordersInWork);
                    } else {
                        await indexedDB.remove('mircity-ordersInWork');
                    }
                } catch (e) {}
                
                await this.checkOrdersInWork();
            },

            waitProcessing(val = false) {
                this.inProcess = !!val;
            },

            // ! Should be deleted after some weeks...
            async clearOldStorage() {
                const ordersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                if (!ordersInWork?.length) {
                    return;
                }

                const clearedOrders = ordersInWork?.filter(order => order != null && order.constructor === Object);
                await indexedDB.set('mircity-ordersInWork', clearedOrders);
            },

            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
        }
    };
</script>
