<template>
    <Stories 
        v-show="shown"
        :stories="items"
        :duration="storiesSettings.show_time"
        @stories-end="storiesEnd" />
</template>

<script>
    import Stories from '@/components/common/AllStories';
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';

    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        components: { 
            Stories
        },
        data: () => ({
            shown: false,
            items: []
        }),

        computed: {
            ...mapState('settings', {
                stories: state => state.settings.stories,
                storiesSettings: state => state.settings.stories_settings,
            }),
        },

        async mounted() {
            await store.dispatch('settings/getStoriesSettings');
            const this_ = this;
            this_.$root.$on('allStoriesStart', async loading => {
                await store.dispatch('settings/getStories');
                let storiesStorage;
                if (loading) {
                    storiesStorage = await indexedDB.get('mircity-activeAllStories') || [];
                }
                if (this_.stories) {
                    const item = {
                        username: 'MIRCITY',
                        picture: '',
                        time: '24h',
                        images: [],
                        button_name: '',
                        root_name: '',
                        color_button: '',
                        stories_name: '',
                    };

                    for (const story of this_.stories) {
                        if (story.pictures) {
                            if (loading) {
                                const checkStories = storiesStorage.find(a => a === story.id);
                                if (checkStories) {
                                    continue;
                                }
                                storiesStorage.push(story.id);
                            }
                            for (const picture of story.pictures) {
                                item.images.push({
                                    url: this_.imageSrc(picture.picture),
                                    viewed: false,
                                    btn_name: story.button_name,
                                    btn_url: story.link || '/',
                                    btn_color: story.color_button || '#fd7d89',
                                    stories_name: story.title,
                                });
                            }   
                        }
                    }
                    if (item.images.length > 0 && !this_.shown) {
                        if (loading) {
                            await indexedDB.set('mircity-activeAllStories', storiesStorage);
                        }
                        this_.items = [ item ];
                        this_.shown = true;
                        this_.$root.$emit('storiesShown');
                        this_.$root.$emit('сommonAllStoriesStart');

                        sendAppMetricaEvent('ALL_STORIES_OPEN', {
                            Action: 'view',
                        });
                    }
                }
            });
        },

        methods: {
            imageSrc(picture) {
                return `${process.env.VUE_APP_IMAGES_URL}/${ picture }`;
            },
            storiesEnd () {
                this.shown = false;
            }
        }
    };
</script>