const moment = require('moment-timezone');
require('moment/locale/ru');

moment.locale('ru');
moment.tz.setDefault('Europe/Moscow');

export const isClosed = entity => { // открыто
    const currentWeekDay = moment().isoWeekday();
    if(!entity.workingHours || !entity.workingHours.length || !entity.enabled) {
        return true;
    }
    const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
    for(const schedule of entity.workingHours) {

        if (schedule.open_weekday === 7) schedule.open_weekday = 0;
        if (schedule.close_weekday === 7) schedule.close_weekday = 0;
        if (currentWeekDay === schedule?.open_weekday && schedule?.stop_until) {
            const isNow = moment().isSameOrBefore(schedule?.stop_until);
            if (isNow) {
                schedule.open_hours = moment(schedule.stop_until).hours();
                schedule.open_minutes = moment(schedule.stop_until).minutes();

                entity.hide = false;
            }
        }
        const openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        const closeTime = (schedule.close_weekday * 24 * 60) + (schedule.close_hours * 60) + schedule.close_minutes;
        // if (schedule.around_the_clock) {
        //     return false;
        // }
        // if (!schedule.enabled) {
        //     return true;
        // }
        if(openTime <= closeTime && openTime <= currentTime && currentTime <= closeTime) {
            return false;
        }
        if(openTime > closeTime && (openTime <= currentTime || currentTime <= closeTime)) {
            return false;
        }
    }
    return true;
};

export const isOpenEntities = entities => { // открыто
    const openServices = [];
    const closedServices = [];
    const currentWeekDay = moment().isoWeekday();
    const filtered = entities.map(entity => {
        if(!entity.workingHours || !entity.workingHours.length || !entity.enabled) {
            entity.hide = true;
            return {
                ...entity,
                closed: true
            };
        }

        const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
        for(const schedule of entity.workingHours) {
            if (schedule.open_weekday === 7) schedule.open_weekday = 0;
            if (schedule.close_weekday === 7) schedule.close_weekday = 0;
            if (currentWeekDay === schedule?.open_weekday && schedule?.stop_until) {
                const localStopUntil = moment.tz(schedule?.stop_until, 'UTC').tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss');
                const isNow = localStopUntil ? moment().isSameOrBefore(localStopUntil) : false;
                if (isNow) {
                    schedule.open_hours = moment(schedule.stop_until).hours();
                    schedule.open_minutes = moment(schedule.stop_until).minutes();

                    entity.hide = false;
                }
            }
            const openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
            const closeTime = (schedule.close_weekday * 24 * 60) + (schedule.close_hours * 60) + schedule.close_minutes;
            // if (schedule.around_the_clock) {
            //     console.log('aeaeaeae');
            //     return {
            //         ...entity,
            //         closed: false
            //     }; 
            // }
            if(openTime <= closeTime && openTime <= currentTime && currentTime <= closeTime) {
                return {
                    ...entity,
                    closed: false
                };
            }
            if(openTime > closeTime && (openTime <= currentTime || currentTime <= closeTime)) {
                return {
                    ...entity,
                    closed: false
                };
            }
        }
        return {
            ...entity,
            closed: true
        };
    });
    filtered.forEach(item => {
        if (item.closed) {
            closedServices.push(item)
        } else {
            openServices.push(item)
        }
    });
    return openServices.concat(closedServices);
};

export const closeUntil = entity => { // закрыт до
    const weekdays = {
        1: 'понедельника',
        2: 'вторника',
        3: 'среды',
        4: 'четверга',
        5: 'пятницы',
        6: 'субботы',
        0: 'воскресенья',
    };

    if(entity.workingHours && entity.workingHours.length) {
        const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
        let index = 0;
        let schedule = entity.workingHours[index];
        let openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        while(openTime < currentTime && entity.workingHours[index]) {
            schedule = entity.workingHours[index];
            openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
            index++;
        }
        if(openTime < currentTime) {
            schedule = entity.workingHours[0];
        }
        
        return `${ weekdays[schedule.open_weekday] } ${ schedule.open_hours.toString().length === 1 ? '0' + schedule.open_hours : schedule.open_hours }:${ schedule.open_minutes.toString().length === 1 ? '0' + schedule.open_minutes : schedule.open_minutes }`;
    }
    return '';
};

export const openAt = entity => { // открывается
    const weekdays = {
        1: 'в понедельник в ',
        2: 'во вторник в ',
        3: 'в среду в',
        4: 'в четверг в',
        5: 'в пятницу в',
        6: 'в субботу в',
        0: 'в воскресенье в',
    };

    if(entity.workingHours && entity.workingHours.length) {
        const currentTime = (moment().day() * 24 * 60) + (moment().hours() * 60) + moment().minutes();
        let index = 0;
        let schedule = entity.workingHours[index];
        let openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
        while(openTime < currentTime && entity.workingHours[index]) {
            schedule = entity.workingHours[index];
            openTime = (schedule.open_weekday * 24 * 60) + (schedule.open_hours * 60) + schedule.open_minutes;
            index++;
        }
        if(openTime < currentTime) {
            schedule = entity.workingHours[0];
        }
        
        return `${ weekdays[schedule.open_weekday] } ${ schedule.open_hours.toString().length === 1 ? '0' + schedule.open_hours : schedule.open_hours }:${ schedule.open_minutes.toString().length === 1 ? '0' + schedule.open_minutes : schedule.open_minutes }`;
    }
    return '';
};


// isOpen() {
//     return helpersEntity.isOpen(this.entity);
// },
// openAt() {
//     return helpersEntity.openAt(this.entity);
// }