import { openDB } from 'idb';

const dbPromise = openDB('mircityDB', 1, {
    upgrade(db) {
        db.createObjectStore('keyval');
    },
});

export default {
    get: async (key) => {
        try {
            return await (await dbPromise).get('keyval', key);
        } catch(e) {}
        return null;
    },
    set: async (key, val) => {
        try {
            return await (await dbPromise).put('keyval', val, key);
        } catch(e) {}
        return null;
    },
    remove: async (key) => {
        try {
            return await (await dbPromise).delete('keyval', key);
        } catch(e) {}
        return null;
    },
    clear: async () => {
        try {
            return await (await dbPromise).clear('keyval');
        } catch(e) {}
        return null;
    },
    keys: async () => {
        try {
            return await (await dbPromise).getAllKeys('keyval');
        } catch(e) {}
        return null;
    }
};