import axios from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        list: [],
        entity: {}
    },

    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        }
    },
    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/customer/addresses');
            commit('SET_LIST', data);
            if(data && data[0]) {
                commit('SET_ENTITY', data[0]);
            }
        },
        async create({ dispatch }, { address }) {
            if(address.id) {
                await axios.put(`/front/customer/address/${ address.id }`, address);
            } else {
                delete address.id;
                await axios.post('/front/customer/address', address);
            }
            dispatch('fetch');
        },
        async delete({ dispatch }, { id }) {
            await axios.delete(`/front/customer/address/${ id }`);
            // dispatch('fetch');
        }
    }
};
