import axios from '../../plugins/axios';
import axiosSilent from '../../plugins/axios-silent';
import indexedDB from '../../plugins/indexedDB';
import { sendAppMetricaEventPurchase } from '../../js/utils/orders';

export default {
    namespaced: true,
    state: {
        list: [],
        active: [],
        activeCount: 0,
        completed: [],
        completedCount: 0,
        count: 0,
        entity: {},
        entities: [],
        lastEntity: {},
        ordersStatuses: [],
        activeOrderSession: false,
    },

    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_ACTIVE_LIST(state, payload) {
            if(payload.page === 1) {
                state.active = [];
            }
            state.active = state.active.concat(payload.results);
            state.activeCount = payload.total;
        },
        SET_COMPLETED_LIST(state, payload) {
            if(payload.page === 1) {
                state.completed = [];
            }
            state.completed = state.completed.concat(payload.results);
            state.completedCount = payload.total;
        },
        SET_COUNT(state, payload) {
            state.count = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_LAST_ENTITY(state, payload) {
            state.lastEntity = payload;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ORDERS_FOR_STATUSES(state, payload) {
            state.ordersStatuses = payload.map(item => ({
                ...item,
                paidWaitingFree: true,
                paidWaitingTimer: ''
            }));
        },
        DELETE_ENTITY(state, payload) {
            state.entities = [...state?.entities?.filter(order => +order.id !== +payload)];
        },
        SET_ACTIVE_ORDER_SESSION(state, payload) {
            state.activeOrderSession = payload;
        }
    },

    actions: {
        async fetch({ commit }, { statuses, page }) {
            try {
                const { data } = await axios.get('/front/orders', { params: { statuses, page } });
                switch (statuses) {
                    case 'active':
                        commit('SET_ACTIVE_LIST', { ...data, ...{ page } });
                        break;
                    case 'completed':
                        commit('SET_COMPLETED_LIST', { ...data, ...{ page } });
                        break;
                    default:
                        commit('SET_LIST', data.results);
                        break;
                }
                commit('SET_COUNT', data.total);
            } catch (e) {}
        },
        async getOrdersForStatuses({ commit }, { page }) {
            try {
                const { data } = await axiosSilent.get('/front/orders/items/get-by-statuses', { params: { page } });
                commit('SET_ORDERS_FOR_STATUSES', data);
                return data;
            } catch (error) {
                commit('SET_ORDERS_FOR_STATUSES', []);
                return [];
            }
        },
        async get({ commit }, { orderId }) {
            const { data } = await axios.get(`/front/orders/${ orderId }`);
            commit('SET_ENTITY', data);
            return data;
        },
        async getLast({ commit }) {
            const { data } = await axios.get(`/front/orders/last`);
            commit('SET_ENTITY', data);
            return data;
        },
        async getWithoutDetails({ commit }, { orderId }) {
            const { data } = await axios.get(`/front/orders/without-details/${ orderId }`);
            commit('SET_ENTITY', data);
            return data;
        },
        async getByIds({ commit }, { orderIds }) {
            const user = await indexedDB.get('mircity-user');
            if(user && user.phone) {
                const { data } = await axios.get('/front/orders/items/get-by-ids', { params: { orderIds: JSON.stringify(orderIds) } })
                commit('SET_ENTITIES', data);
                return data;
            }
        },
        async validate({ commit }, { order }) {
            const { data } = await axios.post('/front/orders/validate', order, { timeout: 60000 });
            if(data.order) {
                await indexedDB.set('mircity-lastValidatedOrder', data.order);
            }
            return data;
        },
        async validatePromocode({ commit }, { order }) {
            const { data } = await axios.post('/front/orders/validate-promocode', order);
            return data;
        },
        async create({ commit }, { order }) {
            const { data } = await axios.post('/front/orders/create', order, { timeout: 60000 });
            if(data.order) {
                const getOrdersInWork = await indexedDB.get('mircity-ordersInWork') || [];
                const order = { ...data.order, checkedStatuses: [] };
                await indexedDB.set('mircity-ordersInWork', [...getOrdersInWork, order]);
                commit('SET_ACTIVE_ORDER_SESSION', true);
                await indexedDB.remove('mircity-pushNotificationShow');
                await indexedDB.remove('mircity-updatePushNotificationDB');
            }
            return data;
        },
        async sendReview({ commit }, { review }) {
            const { data } = await axios.post('/front/orders/review', review);
            commit('DELETE_ENTITY', review?.order_id);
            return data;
        },
        async cancel({ commit }, { orderId }) {
            const { data } = await axios.post('/front/orders/cancel/' + orderId);
            commit('DELETE_ENTITY', orderId);
            return data;
        },
        async tips({ commit }, payment) {
            const { data } = await axios.post('/front/tips/pay', payment);
            return data;
        },
        updateActiveOrderSession({ commit }) {
            commit('SET_ACTIVE_ORDER_SESSION', false);
        },
        async sendLocalPush({ commit }, { push_data, device_id }) {
            const { data } = await axios.post('/front/customer/local-push/send', { push_data, device_id } );
            return data;
        },
        async createLocalPush({ commit }, { push_data, device_id }) {
            const { data } = await axios.post('/front/customer/local-push', { push_data, device_id } );
            return data;
        },
        async deleteLocalPush({ commit }, { push_data, device_id }) {
            const { data } = await axios.post('/front/customer/local-push/delete', { push_data, device_id } );
            return data;
        },
        async createSbpPayment({ commit }, { payment }) {
            await axios.post('/front/orders/sbp-payment', payment);
        },


        // async payTips({ commit }, { payment }) {
        //     const { data } = await axios.post('/front/orders/tips', payment);
        //     return data;
        // },
    }
}
