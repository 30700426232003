<template>
    <f7-sheet
        v-if="isCordova"
        class="timeout-error-sheet"
        backdrop
        swipe-to-close
        close-by-backdrop-click
        close-by-outside-click
        @sheet:close="onClose"
    >
        <div>
            <div class="timeout-error-sheet__title">
                Неполадки с интернет-соединением
            </div>
            <div class="timeout-error-sheet__img"></div>
            <div class="timeout-error-sheet__text">
                Пожауйста, проверьте интернет-соединение и попробуйте обновить страницу
            </div>
            <div class="timeout-error-sheet__info" v-html="info"></div>
        </div>
        <div>
            <button
                :class="`custom-button timeout-error-sheet__button-submit__reverse`"
                @click.stop="goHome"
            >
                На главную
            </button>
            <button
                :class="`custom-button timeout-error-sheet__button-submit`"
                @click.stop="close"
            >
                Обновить страницу
            </button>
        </div>
    </f7-sheet>

    <div v-else-if="shown && isCordova === false">
        <div class="snackbar-backdrop" @click="close"></div>
        <div class="snackbar">
            <div class="snackbar__img"></div>
            <div class="snackbar__title">
                Загрузилось не все
            </div>
            <div class="snackbar__text">
                Проверьте интернет-соединение<br />и обновите страницу
            </div>
            <button class="snackbar__button" @click="close">Обновить страницу</button>
        </div>
    </div>
</template>


<script>
    import { Device } from 'framework7';
    import { bus } from '../../js/utils/bus';
    const { version } = require('../../../package.json');

    export default {
        name: 'TimeoutErrorSheet',
        data() {
            return {
                shown: false,
                error: '',
                request: '',
            };
        },
        computed: {
            info() {
                return `${version}<br>${this.request} ${this.error}`;
            },
            isCordova() {
                return Device.cordova ? true : false;
            },
        },
        mounted() {
            bus.$on('timeoutErrorSheetShow', ({ error, request }) => {
                this.error = error;
                this.request = request;
                this.$f7.sheet.open('.timeout-error-sheet');
                this.shown = true;
            });
        },
        methods: {
            goHome() {
                this.$f7.views[0].router.navigate({ name: 'home' });
                this.$f7.sheet.close();
                this.shown = false;
            },
            close() {
                this.shown = false;
                this.$f7.sheet.close();
                window.location.reload();
            },
            onClose() {
                window.location.reload();
            },
        },
    };
</script>

<style lang="scss">
.snackbar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.snackbar {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 400px;
    background: #fff;
    border-radius: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    padding: 30px 50px;
    text-align: center;

    &__img {
        height: 80px;
        background: url('../../static/errorPage.png') center center no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 12px;
        color: #000;
    }

    &__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #9FA8B1;
        margin-bottom: 24px;
    }

    &__button {
        padding: 12px 24px;
        width: 60%;


        background: #F1F2F4;
        color: #232321;
        border: #8EA0BB;
        border-radius: 8px;
        margin-bottom: 12px;

        &__reverse {
            background: #FFFFFF;
            color: #8EA0BB;
        }
    }
}

.timeout-error-sheet {
    height: auto;

    .sheet-modal-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 30px 20px 20px !important;
    }

    &__img {
        height: 180px;
        background: url('../../static/errorPage.png') center center no-repeat;
        background-size: contain;
        margin: 0 -20px 45px;
    }
    &__title {
        margin: 0 0 45px;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }
    &__text {
        margin: 0 0 45px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #000000;
    }
    &__info {
        margin: 0 0 45px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #8EA0BA;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        > button {
            width: 48%;
        }
    }
    &__button-submit {
        background: #8EA0BB;
        color: #FFFFFF;
        border: 1px solid #8EA0BB;
        border-radius: 10px;
        margin-bottom: 12px;

        &__reverse {
            background: #FFFFFF;
            color: #8EA0BB;
        }
    }
}
</style>
