<template>
    <div class="stories-wrapper">
        <div
            class="close"
            @click="closeStories"></div>
        <div class="stories">
            <div
                v-for="(story, index) in stories"
                :key="index"
                class="story"
                :style="index === indexSelected ? `transform: translate(0px)` : `transform: translate(${calculateTransform(index)}px) scale(0.3); cursor:pointer;`"
                @click="index !== indexSelected ? selectSlide(index) : ''"
            >
                <!-- <div v-for="(img, idx) in stories[0].images" :key="idx">
                    <link
                        v-if="allImg.length < stories[0].images.length"
                        :key="idx"
                        rel="preload"
                        :href="imageSrc(img.url)"
                        as="image" />
                </div> -->
                <div class="story__source" @click="isPaused ? playStory($event) : pauseStory($event)">
                    <video
                        v-if="getSrc(story, index).type === 'video'"
                        :id="getSrc(story, index).url"
                        :src="getSrc(story, index).url"
                        autoplay></video>
                    <img
                        v-else
                        :src="getSrc(story, index).url"
                        alt="" />
                    <div
                        v-if="index === indexSelected"
                        class="story__header"
                        style="padding: 55px 11px">
                        <div class="time">
                            <div
                                v-for="(elm, index) in story.images.length"
                                :key="index"
                                class="time__item">
                                <div class="time__fill" :style="index === key ? `width: ${percent}%` : key > index ? `width:100%` : `width:0%`"></div>
                            </div>
                        </div>
                    </div>
                    <div class="story__body">
                        <div v-if="index !== indexSelected" class="user">
                            <div class="user__image" :style="getNotViewedIndex(story) === -1 ? `background: #FFFFFF` : ''">
                                <img :src="story.picture" alt="" />
                            </div>
                            <div class="user__name">
                                {{ story.username }}
                            </div>
                        </div>
                        <slot
                            v-if="showInnerContent && index === indexSelected"
                            name="innerContent"
                            :story="story"></slot>
                    </div>
                </div>
                <button
                    v-if="stories[0].button_name"
                    class="button_banner"
                    style="z-index: 1000003"
                    :style="{ background: stories[0].color_button }"
                    @click="onClick(stories[0])">{{ stories[0].button_name ? stories[0].button_name : "За покупками" }}</button>
                <div
                    v-if="index === indexSelected"
                    class="story__icon story__icon--prev"
                    @click="prev(index)"></div>
                <div
                    v-if="index === indexSelected"
                    class="story__icon story__icon--next"
                    @click="next(index)"></div>
                <slot
                    v-if="showOuterContent && index === indexSelected"
                    name="outerContent"
                    :story="story"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        name: 'Stories',
        props: {
            stories: {
                type: Array,
                required: true,
            },
            duration: {
                type: Number,
                default: 3000,
            },
            currentIndex: {
                type: Number,
                default: 0,
            },
            showInnerContent: {
                type: Boolean,
                default: false,
            },
            showOuterContent: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            indexSelected: 0,
            difference: 0,
            key: 0,
            percent: 0,
            timer: 0,
            progress: 0,
            interval: 0,
            isPaused: false,
            newDur: 0,
            pausePer: 0,
            allImg: [],
        }),
        computed: {
            isAllStoriesEnd() {
                return this.indexSelected >= this.stories.length - 1 && this.isCurrentAllImagesEnd;
            },
            isCurrentAllImagesEnd() {
                return this.key >= this.stories[this.indexSelected].images.length - 1;
            },
        },
        mounted() {
            const this_ = this;
            this_.$root.$on('commonStoriesStart', async () => {
                await new Promise(resolve => setTimeout(resolve, 100));
                this_.play();
            });
        },
        methods: {
            imageSrc(picture) {
                this.allImg.push(picture);
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${ picture }` : '';
            },
            async onClick(story) {  
                sendAppMetricaEvent('BANNER_STORIES_BUTTON_CLICK', {
                    Action: 'click',
                    Stories: `${story.stories_name}`,
                    ButtonUrl: `${story.root_name}`,
                });

                await f7.views[0].router.navigate(story.root_name);
                clearInterval(this.progress);
                clearInterval(this.interval);
                this.indexSelected = 0;
                this.difference = 0;
                this.key = 0;
                this.percent = 0;
                this.timer = 0;
                this.progress = 0;
                this.interval = 0;
                this.isPaused = false;
                this.newDur = 0;
                this.pausePer = 0;
                this.allImg = [];
                this.$emit('stories-end');
            },
            getSrc(story, index) {
                const viewedIndex = this.getLastViewedIndex(story);
                return index === this.indexSelected
                    ? {
                        url: story.images[this.key] ? story.images[this.key].url : '',
                        type: story.images[this.key] ? story.images[this.key].type : '',
                    }
                    : {
                        url: story.images[viewedIndex] ? story.images[viewedIndex].url : '',
                        type: story.images[viewedIndex] ? story.images[viewedIndex].type : '',
                    };
            },
            getNotViewedIndex(story) {
                return story.images.findIndex(item => !item.viewed);
            },
            getLastViewedIndex(story) {
                const keyIndex = this.getNotViewedIndex(story);
                const index = story.images.length - 1;
                return keyIndex === -1 ? index : keyIndex;
            },
            selectSlide(index) {
                this.isPaused = false;
                this.difference += this.indexSelected - index;
                this.stopVideo(this.stories[this.indexSelected].images[this.key].url);
                this.indexSelected = index;
                this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
                this.reset();
            },
            onAllStoriesEnd() {
                this.difference = 0;
                this.indexSelected = 0;
                this.key = 0;
                this.$emit('stories-end');
            },
            onCurrentAllImagesEnd(index) {
                this.difference += index - (index + 1);
                this.stopVideo(this.stories[this.indexSelected].images[this.key].url);
                this.stories[index].images[this.key].viewed = true;
                this.indexSelected++;
                this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
                this.$emit('сurrentAllImagesEnd', index);
            },
            onCurrentImageEnd(index) {
                this.stories[index].images[this.key].viewed = true;
                this.$emit('сurrentImageEnd', this.key);
                this.key++;
            },
            next(index) {
                this.isPaused = false;
                if (this.isAllStoriesEnd) {
                    this.onAllStoriesEnd();
                } else if (this.isCurrentAllImagesEnd) {
                    setTimeout(() => {
                        this.onCurrentAllImagesEnd(index);
                    });
                } else {
                    this.stories[this.indexSelected].images[this.key].viewed = true;
                    this.key++;
                }
                this.reset();
            },
            prev(index) {
                this.isPaused = false;
                if (this.indexSelected <= 0 && this.key <= 0) {
                    this.key = 0;
                } else if (this.key <= 0) {
                    // Without delay
                    setTimeout(() => {
                        this.difference += index - (index - 1);
                        this.indexSelected--;
                        this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
                    });
                } else {
                    this.key--;
                    this.stories[this.indexSelected].images[this.key].viewed = false;
                }
                this.reset();
            },
            autoPlay() {
                if (this.isAllStoriesEnd) {
                    this.onAllStoriesEnd();
                } else if (this.isCurrentAllImagesEnd) {
                    this.onCurrentAllImagesEnd(this.indexSelected);
                } else {
                    this.onCurrentImageEnd(this.indexSelected);
                }
                this.reset();
            },
            play() {
                this.timer = new Date().getTime();
                this.progress = setInterval(() => {
                    // forward
                    let time = new Date().getTime();
                    if (this.newDur > 0) {
                        this.percent = Math.min(100, this.pausePer + Math.floor((100 * (time - this.timer)) / this.duration));
                    } else {
                        this.percent = Math.min(100, Math.floor((100 * (time - this.timer)) / this.duration));
                    }
                }, this.duration / 100);
                if (this.newDur > 0) {
                    this.interval = setTimeout(this.autoPlay, this.newDur);
                } else {
                    this.interval = setTimeout(this.autoPlay, this.duration);
                }
            },
            reset() {
                this.percent = 0;
                clearInterval(this.interval);
                clearInterval(this.progress);
                this.newDur = 0;
                this.play();
            },
            pauseStory(event) {
                if (event) {
                    this.toggleVideo('pause', event);
                }
                this.isPaused = true;
                this.pausePer = this.percent;
                clearInterval(this.progress);
                clearInterval(this.interval);
                this.newDur = this.duration - (this.pausePer * this.duration) / 100;
            },
            playStory(event) {
                if (event) {
                    this.toggleVideo('play', event);
                }
                this.isPaused = false;
                this.play();
            },
            toggleVideo(type, event) {
                const video = document.getElementById(event.target.id);
                if (video) {
                    video[type]();
                }
            },
            stopVideo(id) {
                const video = document.getElementById(id);
                if (video) {
                    video.pause();
                    video.currentTime = 0;
                }
            },
            calculateTransform(index) {
                if (this.indexSelected - index === -1 || this.indexSelected - index === 1) {
                    return 315 * (index + this.difference);
                }
                if (index > this.indexSelected) {
                    return (315 + 315 * (index + this.difference)) * 0.5;
                } else {
                    return Math.abs((315 - 315 * (index + this.difference)) * 0.5) * -1;
                }
            },
            closeStories() {
                sendAppMetricaEvent('BANNER_STORIES_CLOSE', {
                    Stories: `${this.stories[0].stories_name}`,
                });
                clearInterval(this.progress);
                clearInterval(this.interval);
                this.indexSelected = 0;
                this.difference = 0;
                this.key = 0;
                this.percent = 0;
                this.timer = 0;
                this.progress = 0;
                this.interval = 0;
                this.isPaused = false;
                this.newDur = 0;
                this.pausePer = 0;
                this.allImg = [];
                this.$emit('stories-end');
            },
        },
    };
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.stories-wrapper {
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(16, 16, 16, 0.98);
    font-family: sans-serif;

    > .close {
        z-index: 200;
    }
}
.stories {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.story {
    position: absolute;
    transition: transform 0.2s cubic-bezier(0.4, 0, 1, 1);
    @media screen and (max-width: 768px) {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    &__source {
        position: relative;
        border-radius: 16px;
        background: #000000;
        width: 100vw;
        height: 100vh;
        background-size: contain;
        @media screen and (max-width: 768px) {
            width: 100%;
            height: auto;
            // flex: 1 1 auto;
        }
        img,
        video {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            display: block;
        }
    }
    &__header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 8px 20px;
    }
    &__time {
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
    }
    &__top {
        display: flex;
        align-items: center;
    }
    &__body {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .user {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        .user__name {
            display: none;
        }
        .user__image {
            width: 140px;
            height: 140px;
            background: linear-gradient(180deg, #4c7cf6 0%, #6200c3 100%);
            margin: 0;
            padding: 5px;
            img {
                border: 5px solid #ffffff;
                border-radius: 100%;
            }
        }
    }
    &__icon {
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 768px) {
            top: 0;
            bottom: 0;
            transform: none;
            width: 100px;
            height: auto;
            background: none;
        }
        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 9px;
            border: solid #323232;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(135deg);
            border-radius: 1px;
            @media screen and (max-width: 768px) {
                content: none;
            }
        }
        &--prev {
            left: -35px;
            @media screen and (max-width: 768px) {
                left: 0;
            }
        }
        &--next {
            right: -35px;
            transform: translateY(-50%) rotate(180deg);
            @media screen and (max-width: 768px) {
                right: 0;
                transform: none;
            }
        }
    }
}
.user {
    display: flex;
    align-items: center;
    &__image {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        overflow: hidden;
        margin-right: 8px;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
        margin-right: 5px;
    }
}
.time {
    display: flex;
    margin-bottom: 10px;
    &__item {
        position: relative;
        width: 100%;
        height: 2px;
        margin-right: 4px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__fill {
        position: absolute;
        width: 100%;
        background: #ffffff;
        height: 2px;
    }
}
.close {
    background: url("../../static/close-circle.svg") no-repeat 50%;
    position: absolute;
    width: 28px;
    height: 28px;
    top: 10%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.button_banner {
    position: absolute;
    padding: 14px 25px;
    width: 90%;
    border-radius: 10px;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}
</style>
