import axios from '../../plugins/axios';

export default {
    namespaced: true,
    state: {
        list: []
    },

    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        }
    },
    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/customer/cards-tips');
            commit('SET_LIST', data);
        },
        async create({ dispatch }, { last_four, name }) {
            await axios.post('/front/customer/card-tips', { main: true, last_four, name });
            dispatch('fetch');
        },
        async delete({ dispatch }, { id }) {
            await axios.delete(`/front/customer/card-tips/${ id }`);
            dispatch('fetch');
        }
    }
};
