import axios from '@/plugins/axios'

export const namespaced = true;

export const state = () => ({
    images: {},
});

export const mutations = {
    CLEAR(state) {
        state.images = {};
    },
    SET_IMAGES(state, payload) {
        state.images = payload;
    },
};

export const actions = {
    async fetchDefaultImages({ commit }, { type }) {
        const { data } = await axios.get(`/front/default-settings/images/${type}`);
        commit('SET_IMAGES', (typeof data === 'object') ? data : {});
    },
};

export const getters = {
    productPlaceholder: state => {
        return state?.images?.product_palceholder ? `${process.env.VUE_APP_IMAGES_URL}/${state?.images?.product_palceholder}` : '';
    },
};
