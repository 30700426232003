<template>
    <f7-sheet
        :opened="shown"
        class="repeat-order-sheet"
        style="height:auto; --f7-sheet-bg-color: #fff;"
        swipe-to-close
        @sheet:close="closeSheet"
    >
        <template v-if="lastOrder && entity">
            <div @click="openOrder">
                <div>
                    <div class="repeat-order-sheet__header">
                        <div class="repeat-order-sheet__swipe-line"></div>
                        <div v-if="settings.title" class="repeat-order-sheet__title">{{ settings.title }}</div>
                        <div v-if="settings.subtitle" class="repeat-order-sheet__subtitle">{{ settings.subtitle }}</div>
                        <div v-if="entity" class="repeat-order-sheet__entity">Откуда: <span>{{ entity.title }}</span></div>
                    </div>
                </div>

                <div class="repeat-order-sheet__content">
                    <f7-swiper class="repeat-order-sheet__carousel__swiper" :params="{ spaceBetween: 10, slidesPerView: 4.5 }">
                        <template v-for="(item, index) in orderItems">
                            <f7-swiper-slide :key="index">
                                <div class="repeat-order-sheet__carousel__content">
                                    <div class="repeat-order-sheet__carousel__picture">
                                        <div v-if="item.picture" :style="`background-image: url(${ imageSrc(item.picture) })`"></div>
                                        <div v-else class="entity-product-card__picture__empty"></div>
                                    </div>
                                </div>
                            </f7-swiper-slide>
                        </template>
                    </f7-swiper>

                    <button
                        :class="`custom-button repeat-order-sheet__button gradient-${BUSINESS_TYPE_MAP[lastOrder.type]}`"
                        @click.stop="repeatOrder"
                    >
                        <template v-if="loading">
                            <div class="repeat-order-sheet__button__preloader">
                                <f7-preloader class="color-white" size="22" />
                            </div>
                        </template>
                        <template v-else>
                            <span>Добавить в корзину ({{ orderItems.length }})</span>
                            <span>{{ priceFormat(lastOrder.price) }}&nbsp;Р</span>
                        </template>
                    </button>
                </div>
            </div>
        </template>
        <RestaurantProductDetail
            v-if="detailProductId.restaurant"
            :entityCode="detailEntityCode.restaurant"
            :productId="detailProductId.restaurant"
            :show="detailProductSheetShow.restaurant"
            @close="detailProductSheetShow.restaurant = false"
        />
        <GrocerProductDetail
            v-if="detailProductId.grocer"
            :entityCode="detailEntityCode.grocer"
            :productId="detailProductId.grocer"
            :show="detailProductSheetShow.grocer"
            @close="detailProductSheetShow.grocer = false"
        />
    </f7-sheet>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { mapState } from 'vuex';
    import store from '@/store';
    import axios from '@/plugins/axios';
    import indexedDB from '@/plugins/indexedDB';

    import RestaurantProductDetail from '@/components/restaurant/ProductDetail';
    import GrocerProductDetail from '@/components/grocery/ProductDetail';

    import { sendAppMetricaEvent, priceFormat, getEntitiesClosedList, checkEnabledService } from '@/js/utils/service-helpers';
    import { isOpenEntities } from '@/js/utils/working-hours.js'
    import { ORDER_TYPE, ORDER_STATUS, ORDER_TYPE_SHORT, ENTITY_TYPE, BUSINESS_TYPE_MAP } from '@/js/utils/vars';
    import { repeatOrder } from '@/js/utils/orders';

    export default {
        name: 'RepeatDialog',

        components: {
            RestaurantProductDetail,
            GrocerProductDetail
        },

        data: () => ({
            priceFormat,
            ORDER_TYPE,
            ORDER_STATUS,
            ORDER_TYPE_SHORT,
            ENTITY_TYPE,
            BUSINESS_TYPE_MAP,
            shown: false,
            loading: false,
            lastOrder: null,
            entity: null,
            orderItems: [],
            checkActiveStories: false,

            detailEntityCode: {
                restaurant: '',
                grocer: '',
                entity: ''
            },
            detailProductId: {
                restaurant: 0,
                grocer: 0,
                entity: 0
            },
            detailProductSheetShow: {
                restaurant: false,
                grocer: false,
                entity: false
            },
        }),

        computed: {
            ...mapState('remoteConfig', ['defaultValue', 'config']),
            ...mapState('settings', {
                settings: state => state.settings.order_repeat
            }),
        },

        async mounted() {
            this.$root.$on('storiesShow', () => {
                this.checkActiveStories = true;
            });
            this.$root.$on('repeatOrderSheetShow', async () => {
                if(this.checkActiveStories) {
                    return;
                }

                await store.dispatch('settings/fetch', { page: 'order_repeat' });

                if(this.settings.show) {
                    let orderForRepeat = await indexedDB.get('mircity-orderForRepeat');
                    if (!orderForRepeat) {
                        return;
                    }

                    if([ORDER_STATUS.DONE].indexOf(orderForRepeat.status) >= 0) {
                        this.lastOrder = await store.dispatch('orders/get', { orderId: orderForRepeat.id });
                        if(isOpenEntities([this.lastOrder[ORDER_TYPE_SHORT[this.lastOrder.type]].items[0][ENTITY_TYPE[this.lastOrder.type]]])[0]?.closed) {
                            return;
                        }
                        if (!checkEnabledService(this.lastOrder)) {
                            return;
                        }
                        if(this.lastOrder) {
                            this.entity = this.lastOrder[ORDER_TYPE_SHORT[this.lastOrder.type]]?.items[0][ENTITY_TYPE[this.lastOrder.type]] || {};
                            await this.getOrderItems();

                            if(this.orderItems && this.orderItems.length) {
                                sendAppMetricaEvent('ORDER_REPEAT', {
                                    Action: 'view',
                                    Page: 'main',
                                    Click: ''
                                });
                                this.shown = true;
                            }
                        }
                    }
                    
                }
            });
        },
        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/thumb/${ picture }` : '';
            },
            async closeSheet() {
                await indexedDB.remove('mircity-orderForRepeat');
                window.dispatchEvent(new Event('resize'));
            },
            async openOrder() {
                this.shown = false;
                await f7.views[0].router.navigate({
                    name: 'orders-detail',
                    params: {
                        orderId: this.lastOrder.id
                    }
                });
                sendAppMetricaEvent('ORDER_REPEAT', {
                    Action: 'click',
                    Page: 'main',
                    Click: 'order'
                });
            },
            async openEntity() {
                this.shown = false;
                if(this.lastOrder.type === ORDER_TYPE.RESTAURANT_DELIVERY) {
                    await f7.views[0].router.navigate({
                        name: 'restaurant-detail',
                        params: {
                            restaurantCode: this.entity.code,
                            source: 'other'
                        }
                    });
                }
                if(this.lastOrder.type === ORDER_TYPE.GROCER_DELIVERY) {
                    await f7.views[0].router.navigate({
                        name: 'grocery-detail',
                        params: {
                            grocerCode: this.entity.code
                        }
                    });
                }
                sendAppMetricaEvent('ORDER_REPEAT', {
                    Action: 'click',
                    Page: 'main',
                    Click: 'entity'
                });
                return;
            },
            async openProduct(product, index) {
                this.shown = false;
                if(this.lastOrder.type === ORDER_TYPE.RESTAURANT_DELIVERY) {
                    this.detailEntityCode.restaurant = this.entity.code;
                    this.detailProductId.restaurant = product.id;
                    this.detailProductSheetShow.restaurant = true;  
                }
                if(this.lastOrder.type === ORDER_TYPE.GROCER_DELIVERY) {
                    this.detailEntityCode.grocer = this.entity.code;
                    this.detailProductId.grocer = product.id;
                    this.detailProductSheetShow.grocer = true;
                }

                sendAppMetricaEvent('PRODUCT_CLICK', {
                    Service: this.lastOrder.type,
                    ServiceCode: this.entity.code,
                    ProductId: product.id,
                    Position: index,
                });

                sendAppMetricaEvent('ORDER_REPEAT', {
                    Action: 'click',
                    Page: 'main',
                    Click: 'product'
                });
                return;
            },
            async repeatOrder() {
                if(this.loading) {
                    return;
                }
                this.loading = true;
                this.shown = false;
                await repeatOrder(this.lastOrder);
                sendAppMetricaEvent('ORDER_REPEAT', {
                    Action: 'click',
                    Page: 'main',
                    Click: 'button'
                });
                this.loading = false;
            },
            async getOrderItems() {
                if(this.lastOrder.type === ORDER_TYPE.RESTAURANT_DELIVERY) {
                    const entity_id = this.lastOrder.rd.items[0].restaurant_id;
                    const ids = this.lastOrder.rd.items.map(item => item.dish_id);
                    const { data: products } = await axios.get(`/front/restaurants/${entity_id}/dishes/items?ids=${JSON.stringify(ids)}`);
                    for(const item of this.lastOrder.rd.items.filter(i=> i.enabled)) {
                        const modifierIds = (item.modifiers || []).map(v => v.id);
                        const ingredientIds = (item.ingredients || []).map(v => v.id);

                        for(const product of products) {
                            if(item.dish_id === product.id) {
                                let modifiers = [];
                                let ingredients = [];
                                for(const modifier of product.modifiers) {
                                    if(modifierIds.indexOf(modifier.id) >= 0) {
                                        if(!modifiers.find(m => m.id === modifier.id)) {
                                            modifiers.push(modifier);
                                        }
                                    }
                                }
                                if (product?.modifierGroups?.length) {
                                    for (const group of product.modifierGroups) {
                                        for(const modifier of group.modifiers) {
                                            if(modifierIds.indexOf(modifier.id) >= 0) {
                                                if(!modifiers.find(m => m.id === modifier.id)) {
                                                    modifiers.push(modifier);
                                                }
                                            }
                                        }
                                    }
                                }
                                for(const ingredient of product.ingredients) {
                                    if(ingredientIds.indexOf(ingredient.id) >= 0) {
                                        if(!ingredients.find(i => i.id === ingredient.id)) {
                                            ingredients.push(ingredient);
                                        }
                                    }
                                }
                                if (product?.ingredientGroups?.length) {
                                    for (const group of product.ingredientGroups) {
                                        for(const ingredient of group.ingredients) {
                                            if(ingredientIds.indexOf(ingredient.id) >= 0) {
                                                if(!ingredients.find(i => i.id === ingredient.id)) {
                                                    ingredients.push(ingredient);
                                                }
                                            }
                                        }
                                    }
                                }

                                if(modifiers.length === item.modifiers.length && ingredients.length === item.ingredients.length) {
                                    this.orderItems.push({
                                            ...item,
                                            product_id: product.id,
                                            picture: product.picture
                                    });
                                }
                            }
                        }
                    }
                }
                if(this.lastOrder.type === ORDER_TYPE.GROCER_DELIVERY) {
                    const entity_id = this.lastOrder.gd.items[0].grocer_id;
                    const ids = this.lastOrder.gd.items.map(item => item.goods_id);
                    const { data: products } = await axios.get(`/front/grocers/${entity_id}/product/items?ids=${JSON.stringify(ids)}`);
                    for(const item of this.lastOrder.gd.items.filter(i=> i.enabled)) {
                        for(const product of products) {
                            if(item.goods_id === product.id) {
                                this.orderItems.push({
                                        ...item,
                                        product_id: product.id,
                                        picture: product.main_picture,
                                });
                            }
                        }
                    }
                }
                if(this.lastOrder.type === ORDER_TYPE.ENTITY_DELIVERY) {
                    const entity_id = this.lastOrder.ed.items[0].entity_id;
                    const ids = this.lastOrder.ed.items.map(item => item.entity_product_id);
                    const { data: products } = await axios.get(`/front/entities/${entity_id}/entity_products/items?ids=${JSON.stringify(ids)}`);
                    for(const item of this.lastOrder.ed.items.filter(i=> i.enabled)) {
                        const optionIds = (item.entity_options || []).map(v => v.id);
                        const variantIds = (item.entity_variants || []).map(v => v.id);

                        for(const product of products) {
                            if(item.entity_product_id === product.id) {
                                let options = [];
                                let variants = [];
                                for(const option of product.entityOptions) {
                                    if(optionIds.indexOf(option.id) >= 0) {
                                        if(!options.find(m => m.id === option.id)) {
                                            options.push(option);
                                        }
                                    }
                                }
                                for(const variant of product.entityVariants) {
                                    if(variantIds.indexOf(variant.id) >= 0) {
                                        if(!variants.find(i => i.id === variant.id)) {
                                            variants.push(variant);
                                        }
                                    }
                                }

                                if(options.length === item.entity_options.length && variants.length === item.entity_variants.length) {
                                    this.orderItems.push({
                                            ...item,
                                            product_id: product.id,
                                            picture: product.picture
                                    });
                                }
                            }
                        }
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
.repeat-order-sheet {
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 50px;
    }

    &__swipe-line {
        width: 30px;
        height: 4px;
        background: #EAEAEA;
        border-radius: 40px;
        margin-bottom: 10px;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #90949A;
        margin-bottom: 10px;
    }

    &__entity {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #90949A;

        > span {
            color: #000000;
        }
    }

    &__carousel {

        &__swiper {
            margin: 0 -20px;
            padding: 0 20px;
            margin-top: -36px;
            margin-bottom: 24px;
        }

        &__content {
            width: 100%;
            height: 100%;
            overflow: hidden;
            cursor: pointer;

            &-single {
                width: 100%;
                height: 100%;
            }
        }
        &__picture {
            width: 65px;
            height: 65px;
            border-radius: 14px;
            overflow: hidden;

            > div {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center center;
            }

            &__empty {
                background-image: url('../../static/empty-img.svg');
                
            }
        }
    }

    &__button {
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;

        &__preloader {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
</style>
